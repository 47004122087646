import { TFinding } from '../../models';
import { Link as RouterLink } from 'react-router-dom';
import { getFindingDisplayStatus } from '../../models/finding';
import {
  Avatar,
  Badge,
  Box,
  Flex,
  HStack,
  LinkBox,
  Spacer,
  Tag,
  Text,
  Tooltip,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react';
import { displayFormatedDate, truncateHtml } from '../../utils';
import { TInventoryModel } from '../../models/inventory_model';
import StatusBadge from '../StatusBadge';
import { StatusTagColor, StatusTagTextColor } from '../../colors';

// Only color medium and high severity findings
const severityColor = {
  medium: 'yellow.400',
  high: 'red.500',
};

const statusText = {
  draft: 'DRAFT',
  open: 'OPEN',
  past_due: 'PAST DUE',
  closed: 'CLOSED',
};

function FindingDueDateBox({ finding }: { finding: TFinding }) {
  if (!finding.due_at) {
    return null;
  }
  return <Tag>Due date: {displayFormatedDate(finding.due_at)}</Tag>;
}

interface FindingRowProps {
  finding: TFinding;
  inventoryModel?: TInventoryModel;
}

export default function FindingRow({
  finding,
  inventoryModel,
}: FindingRowProps) {
  const status = getFindingDisplayStatus(finding);
  return (
    <LinkBox
      as={RouterLink}
      data-testid="finding-row"
      to={`/model-inventory/${finding.inventory_model?.cuid}/findings/${finding.cuid}`}
      bg={useColorModeValue('white', 'neutral.850')}
      onClick={e => {
        e.stopPropagation();
      }}
    >
      <Flex
        rounded="md"
        overflow={'hidden'}
        color="inherit"
        border={'1px solid'}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        _hover={{
          bg: useColorModeValue('neutral.100', 'neutral.800'),
          color: useColorModeValue('inherit', 'neutral.200'),
        }}
        transition={'all 0.3s ease-in-out'}
      >
        <HStack w={'full'} p={2}>
          <Box
            w={1.5}
            h={'full'}
            rounded={'full'}
            bg={finding.severity.colors.primary}
          ></Box>
          <VStack
            alignItems="flex-start"
            width="100%"
            gap={0}
            color="inherit"
            p={1}
          >
            <HStack w={'full'}>
              <Box>
                {inventoryModel && <Badge>{finding.project?.type}</Badge>}
                <Text fontWeight="semibold">
                  {finding.key} - {finding.title}
                </Text>
              </Box>
              <Spacer />
              <HStack>
                {finding.owner && (
                  <Tooltip
                    label={`Assigned to ${finding.owner.name}`}
                    aria-label={`Assigned to ${finding.owner.name}`}
                    placement="bottom"
                    hasArrow
                  >
                    <Avatar
                      size="xs"
                      name={finding?.owner.name}
                      src={finding?.owner.picture}
                    />
                  </Tooltip>
                )}
                {status === 'past_due' && (
                  <FindingDueDateBox finding={finding} />
                )}
                <StatusBadge
                  bg={StatusTagColor[status as keyof object]}
                  color={StatusTagTextColor[status as keyof object]}
                >
                  {statusText[status as keyof object]}
                </StatusBadge>
              </HStack>
            </HStack>

            <Text
              dangerouslySetInnerHTML={{
                __html: truncateHtml(finding.description, 250),
              }}
              noOfLines={3}
            ></Text>
          </VStack>
        </HStack>
      </Flex>
    </LinkBox>
  );
}
