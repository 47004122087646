import {
  AvatarGroup,
  Box,
  HStack,
  Link,
  LinkBox,
  Text,
  Tooltip,
  VStack,
  Code,
  Badge,
} from '@chakra-ui/react';
import {
  ArrowForwardIcon,
  Icon,
  InfoOutlineIcon,
  SettingsIcon,
} from '@chakra-ui/icons';
import React, { useContext } from 'react';
import _ from 'lodash';
import { displayRelativeDate } from '../../../utils';
import { LinkProps } from '@chakra-ui/layout';
import { Link as RouterLink } from 'react-router-dom';
import { TEvent } from '../../../models/event';
import { getModelDocumentType } from '../../../models/model_document';

import UsersContext from '../../../contexts/UsersContext';
import StatusBadge from '../../StatusBadge';
import { DOCUMENTATION_SECTION_STATUS } from '../../DocumentSectionStatusSelect';
import AvatarProxy from '../../AvatarProxy';

const getEventUserNameDisplay = (userName: string) => {
  const { currentUser: user } = useContext(UsersContext);
  if (user?.name === userName) {
    return 'You';
  }
  return userName;
};
// Helper to check if we are rendering events inside a project path
const isProjectPath = () => {
  const currentPath = window.location.pathname;
  return currentPath.match(/^\/model-inventory\/\w+/) !== null;
};

// Helper to return "on <project name>" if we are rendering events inside a project path
const projectNameSuffix = (event: TEvent) => {
  if (!isProjectPath()) {
    return (
      <span>
        on{' '}
        {getSafeLink(event.context.link!.label, `${event.context.link!.url}`)}
      </span>
    );
  }
  return '';
};

const inventoryModelNameSuffix = (event: TEvent) => {
  if (!isProjectPath()) {
    return (
      <span>
        on{' '}
        {getSafeLink(
          event.inventory_model.name,
          `/model-inventory/${event.inventory_model.cuid}/overview`,
        )}
      </span>
    );
  }
  return '';
};

interface EventRowLayoutProps {
  event: TEvent;
  circles?: JSX.Element[];
  children: any;
}
function EventRowLayout({ event, circles, children }: EventRowLayoutProps) {
  let avatars = [];
  if (!circles) {
    avatars.push(
      <InfoOutlineIcon
        key={'info'}
        fontSize={20}
        color={'neutral.300'}
        ml={1}
        pr={0.5}
      />,
    );
  } else {
    avatars = circles;
  }
  const [isOpen, setIsOpen] = React.useState(false);
  return (
    <VStack align={'stretch'} data-testid="activity-feed-widget-item">
      <LinkBox onClick={() => setIsOpen(!isOpen)} cursor={'pointer'}>
        <HStack justifyContent={'space-between'}>
          <AvatarGroup size="sm" alignSelf={'flex-start'}>
            {avatars}
          </AvatarGroup>
          <Box flex={1}>{children}</Box>
          <Box alignSelf={'flex-start'}>
            <Text color={'neutral.600'} pl={2}>
              {displayRelativeDate(event.created_at)}
            </Text>
          </Box>
          {/*<Box>{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}</Box>*/}
        </HStack>
      </LinkBox>

      {/*<Collapse in={isOpen}>
        <Box>
          <Text>Hello</Text>
        </Box>
      </Collapse>*/}
    </VStack>
  );
}

interface IVerbRenderer {
  [key: string]: (event: TEvent) => JSX.Element;
}

interface IActionObjectTypeMap {
  [key: string]: IVerbRenderer;
}

const devFrameworkIcon = (
  <Icon as={SettingsIcon} boxSize={6} color={'neutral.200'} />
);

function getSafeLink(label: string, to?: string, linkProps?: LinkProps) {
  if (!linkProps) {
    linkProps = { fontWeight: 'bold' };
  }
  if (to) {
    return (
      <Link as={RouterLink} to={to} {...linkProps}>
        {label}
      </Link>
    );
  }
  return <Text>{label}</Text>;
}

/**
 * Extracts the metadata label from the event display name. This event can be associated
 * with metadata updates or metadata comments (annotations).
 *
 * We do this because in some cases the event might be related to a comment addedd to a
 * test driven block where the associated target is a text block. In this case we want
 * to display the test description title
 */
function getMetadataEventLabel(event: any, contextKey: string = 'target') {
  const defaultLabel = event.context[contextKey]!.displayName.split('.').pop();
  const eventUrl = event.context[contextKey]?.url;
  if (!eventUrl) {
    return defaultLabel;
  }

  // Remove query part as well
  const pathParts = eventUrl.split('?')[0].split('/');
  const contentBlockParts = pathParts[pathParts.length - 1].replace('-', '_');

  // If contentBlockParts starts with "test-description:" then the link belongs to a text block
  // inside a test driven block. The `content_id` is the last part of the path after the colon.
  const isTextBlock =
    contentBlockParts.startsWith('test_description:') ||
    contentBlockParts.startsWith('metric_description:');

  if (!isTextBlock) {
    return defaultLabel;
  }

  const metricTitle = contentBlockParts.split('.').pop();
  return `Description for ${_.startCase(metricTitle)}`;
}

const RENDERERS: IActionObjectTypeMap = {
  InventoryModel: {
    created: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} added{' '}
            {getSafeLink(event.context.link!.label, event.context.link!.url)} to
            the model inventory.
          </Text>
          <Link></Link>
        </EventRowLayout>
      );
    },
    'reset-workflow': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} reset the
            workflow {inventoryModelNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
    'model-archived': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <Icon
              as={SettingsIcon}
              boxSize={6}
              color={'neutral.200'}
              key={event.context.actor.displayName}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} archived{' '}
            {getSafeLink(
              event.context.action_object.displayName,
              `/model-inventory/${event.action_object.cuid}/overview`,
            )}{' '}
            from the model inventory
          </Text>
        </EventRowLayout>
      );
    },
    'model-deleted': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} deleted{' '}
            {event.context.action_object.displayName}
            from the model inventory
          </Text>
        </EventRowLayout>
      );
    },
    'model-restored': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} restored{' '}
            {getSafeLink(
              event.context.action_object.displayName,
              `/model-inventory/${event.action_object.cuid}/overview`,
            )}{' '}
            to the model inventory
          </Text>
        </EventRowLayout>
      );
    },
  },
  Finding: {
    created: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} created a{' '}
            {getSafeLink(
              event.context.action_object.displayName,
              event.context.action_object.url,
            )}
            {' project finding '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
    updated: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} updated{' '}
            {getSafeLink(
              event.context.action_object.displayName,
              event.context.action_object.url,
            )}{' '}
            project finding
          </Text>
        </EventRowLayout>
      );
    },
    deleted: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} deleted{' '}
            {event.context.action_object.displayName} on project findings
          </Text>
        </EventRowLayout>
      );
    },
  },
  Metadata: {
    updated: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} updated{' '}
            {getSafeLink(
              getMetadataEventLabel(event, 'action_object'),
              event.context.action_object.url,
            )}{' '}
            on{' '}
            {event.context.action_object.contentType ===
            getModelDocumentType('documentation')
              ? 'model documentation'
              : 'validation report'}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
    logged: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            event.actor.type == 'User' ? (
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />
            ) : (
              devFrameworkIcon
            ),
          ]}
        >
          <Text>
            {event.actor.type === 'User'
              ? event.context.actor.displayName
              : 'Developer Framework'}{' '}
            logged a new description for Test{' '}
            <Tooltip label={event.context.action_object.displayName} hasArrow>
              <Code>
                {event.context.action_object.displayName.split('.').pop()}
              </Code>
            </Tooltip>{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  Annotation: {
    annotated: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} commented
            on{' '}
            {getSafeLink(
              getMetadataEventLabel(event),
              event.context.target?.url,
            )}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
    'annotated-resolved': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} marked
            annotation as resolved on{' '}
            {getSafeLink(
              event.context.target!.displayName,
              event.context.target?.url,
            )}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
    'annotated-restored': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} restored
            annotation on{' '}
            {getSafeLink(
              event.context.target!.displayName,
              event.context.target?.url,
            )}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  AnnotationCommentMention: {
    'mentioned-in-comments': (event: TEvent) => {
      const { currentUser } = useContext(UsersContext);
      const sameAuthor = event.context.actor.displayName === currentUser!.name;
      const mentionedIsMe =
        event.context.action_object.user.displayName === currentUser!.name;

      let circles = [
        <AvatarProxy
          borderWidth={0}
          key={event.context.actor.cuid}
          name={event.context.actor.displayName}
          src={event.context.actor.picture}
        />,
      ];

      if (!sameAuthor) {
        circles.push(
          <AvatarProxy
            borderWidth={0}
            key={event.context.actor.displayName}
            name={event.context.action_object.user.displayName}
            src={event.context.action_object.user.picture}
          />,
        );
      }

      return (
        <EventRowLayout event={event} circles={circles}>
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} mentioned{' '}
            {mentionedIsMe
              ? 'to you'
              : `to ${event.context.action_object.user.displayName}`}
            {' in a comment on '}
            {getSafeLink(
              event.context.target!.displayName,
              event.context.target?.url,
            )}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  AnnotationComment: {
    replied: (event: TEvent) => {
      const sameAuthor =
        event.context.action_object.displayName ===
        event.context.actor.displayName;
      let circles = [
        <AvatarProxy
          borderWidth={0}
          key={event.context.actor.cuid}
          name={event.context.actor.displayName}
          src={event.context.actor.picture}
        />,
      ];

      if (!sameAuthor) {
        circles.push(
          <AvatarProxy
            borderWidth={0}
            key={event.context.actor.displayName}
            name={event.context.action_object.displayName}
            src={event.context.action_object.picture}
          />,
        );
      }

      return (
        <EventRowLayout event={event} circles={circles}>
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} replied
            to a comment{' '}
            {sameAuthor ? '' : `to ${event.context.action_object.displayName}`}{' '}
            on{' '}
            {getSafeLink(
              event.context.target!.displayName,
              event.context.target?.url,
            )}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  Suggestion: {
    suggested: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} suggested
            content "{event.context.metadata?.type}" on{' '}
            {getSafeLink(
              event.context.target!.displayName,
              event.context.target?.url,
            )}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
    'suggestion-state-changed': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} "
            {event.context.metadata.to_state}" suggestion on{' '}
            {getSafeLink(
              event.context.target!.displayName,
              event.context.target?.url,
            )}{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  TestRun: {
    'run-started': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            event.actor.type == 'User' ? (
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />
            ) : (
              devFrameworkIcon
            ),
          ]}
        >
          <Text>
            {event.actor.type === 'User'
              ? event.context.actor.displayName
              : 'Developer Framework'}{' '}
            started a new test run{' '}
            <Code>({event.context.action_object.displayName})</Code>
          </Text>{' '}
          {projectNameSuffix(event)}
        </EventRowLayout>
      );
    },
  },
  TestResult: {
    logged: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            event.actor.type == 'User' ? (
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />
            ) : (
              devFrameworkIcon
            ),
          ]}
        >
          <Text>
            {event.actor.type === 'User'
              ? event.context.actor.displayName
              : 'Developer Framework'}{' '}
            logged new results for Test{' '}
            <Tooltip label={event.context.action_object.displayName} hasArrow>
              <Code>
                {event.context.action_object.displayName.split('.').pop()}
              </Code>
            </Tooltip>{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  Dataset: {
    logged: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            event.actor.type == 'User' ? (
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />
            ) : (
              devFrameworkIcon
            ),
          ]}
        >
          <Text>
            {event.actor.type === 'User'
              ? event.context.actor.displayName
              : 'Developer Framework'}{' '}
            logged{' '}
            <Tooltip label={event.context.action_object.displayName} hasArrow>
              <Code>
                {event.context.action_object.displayName.split('.').pop()}
              </Code>
            </Tooltip>
          </Text>
        </EventRowLayout>
      );
    },
  },
  MetricResult: {
    logged: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            event.actor.type == 'User' ? (
              <AvatarProxy
                borderWidth={0}
                key={event.context.actor.displayName}
                name={event.context.actor.displayName}
                src={event.context.actor.picture}
              />
            ) : (
              devFrameworkIcon
            ),
          ]}
        >
          <Text>
            {event.actor.type === 'User'
              ? event.context.actor.displayName
              : 'Developer Framework'}{' '}
            logged new results for Metric{' '}
            <Tooltip label={event.context.action_object.displayName} hasArrow>
              <Code>
                {event.context.action_object.displayName.split('.').pop()}
              </Code>
            </Tooltip>{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  Approval: {
    'started-approval': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} started
            approval process {event.context.action_object.displayName}{' '}
            {inventoryModelNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
    'closed-approval': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} closed
            approval process {event.context.action_object.displayName}{' '}
            {inventoryModelNameSuffix(event)}, the result was{' '}
            <Badge>{event.context.metadata.approval.status}</Badge>
          </Text>
        </EventRowLayout>
      );
    },
  },
  ApprovalVoter: {
    'voted-approval': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} voted on
            approval process {event.context.action_object.displayName}{' '}
            {inventoryModelNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  StatusesWorkflowTransitionHistory: {
    'transitioned-status': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
            transitioned{' '}
            {event.context.action_object.transition.from_status && (
              <>
                <StatusBadge
                  color={
                    event.context.action_object.transition.from_status.colors
                      .tertiary
                  }
                  bg={
                    event.context.action_object.transition.from_status.colors
                      .primary
                  }
                >
                  {event.context.action_object.transition.from_status.name}
                </StatusBadge>
                <ArrowForwardIcon />
              </>
            )}
            <StatusBadge
              color={
                event.context.action_object.transition.to_status.colors.tertiary
              }
              bg={
                event.context.action_object.transition.to_status.colors.primary
              }
            >
              {event.context.action_object.transition.to_status.name}
            </StatusBadge>{' '}
            {inventoryModelNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  StatusesWorkflowTransition: {
    'project-transitioned-status': (event: TEvent) => {
      const projectName = event.project.name;

      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)}{' '}
            transitioned{' '}
            <StatusBadge
              color={
                event.context.action_object.transition.from_status.colors
                  .tertiary
              }
              bg={
                event.context.action_object.transition.from_status.colors
                  .primary
              }
            >
              {event.context.action_object.transition.from_status.name}
            </StatusBadge>
            <ArrowForwardIcon />
            <StatusBadge
              color={
                event.context.action_object.transition.to_status.colors.tertiary
              }
              bg={
                event.context.action_object.transition.to_status.colors.primary
              }
            >
              {event.context.action_object.transition.to_status.name}
            </StatusBadge>{' '}
            {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  TemplateVersion: {
    created: (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} created a
            new version of template{' '}
            {getSafeLink(
              event.context.target!.displayName,
              event.context.target?.url,
            )}{' '}
          </Text>
        </EventRowLayout>
      );
    },
    'swapped-template': (event: TEvent) => {
      return (
        <EventRowLayout
          event={event}
          circles={[
            <AvatarProxy
              borderWidth={0}
              key={event.context.actor.displayName}
              name={event.context.actor.displayName}
              src={event.context.actor.picture}
            />,
          ]}
        >
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} swapped
            template from {event.context.action_object!.displayName} to{' '}
            {event.context.target!.displayName} {projectNameSuffix(event)}
          </Text>
        </EventRowLayout>
      );
    },
  },
  DocumentSectionStatus: {
    updated: (event: TEvent) => {
      let circles = [
        <AvatarProxy
          borderWidth={0}
          key={event.context.actor.cuid}
          name={event.context.actor.displayName}
          src={event.context.actor.picture}
        />,
      ];
      const fromStatusObject =
        event.context.action_object.fromStatus === 'In Progress'
          ? DOCUMENTATION_SECTION_STATUS[0]
          : DOCUMENTATION_SECTION_STATUS[1];
      const toStatusObject =
        event.context.action_object.toStatus === 'In Progress'
          ? DOCUMENTATION_SECTION_STATUS[0]
          : DOCUMENTATION_SECTION_STATUS[1];

      return (
        <EventRowLayout event={event} circles={circles}>
          <Text>
            {getEventUserNameDisplay(event.context.actor.displayName)} updated{' '}
            {getSafeLink(
              event.context.action_object.sectionName,
              event.context.action_object?.url,
            )}{' '}
            section status from{' '}
            <StatusBadge
              color={fromStatusObject.color}
              bg={fromStatusObject.bgColor}
            >
              {event.context.action_object.fromStatus}
            </StatusBadge>{' '}
            <ArrowForwardIcon />
            <StatusBadge
              color={toStatusObject.color}
              bg={toStatusObject.bgColor}
            >
              {event.context.action_object.toStatus}
            </StatusBadge>
          </Text>
        </EventRowLayout>
      );
    },
  },
  default: {
    default: (event: TEvent) => {
      return (
        <EventRowLayout event={event}>
          <Text>{event.details}</Text>
        </EventRowLayout>
      );
    },
  },
};
interface IEventRowProps {
  event: TEvent;
}
export default function EventRow({ event }: IEventRowProps) {
  let eventRowDetails;

  try {
    eventRowDetails =
      RENDERERS[event.action_object.type as keyof object][
        event.verb as keyof object
      ](event);
  } catch (KeyError) {
    eventRowDetails = RENDERERS['default']['default'](event);
  }

  return eventRowDetails;
}
