import {
  Box,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  TagLabel,
  Text,
  VStack,
  useDisclosure,
  Select,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  FormControl,
  useColorModeValue,
  ModalFooter,
  Spacer,
  Stack,
  HStack,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Center,
  CircularProgress,
  Button,
  PopoverArrow,
} from '@chakra-ui/react';
import { DataTable } from '../DataTable';
import { useEffect, useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import { displayFormatedDateAndTime } from '../../utils';
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  CalendarIcon,
  CircleStackIcon,
  CubeIcon,
} from '@heroicons/react/24/outline';
import { TInputResult } from '../../api/API';
import { useQuery } from 'react-query';
import { TUser } from '../../models';
import AvatarProxy from '../AvatarProxy';

export interface THistory {
  created_at: number;
  user?: TUser;
  inputs: TInputResult[];
}

interface MetricInputInfoModalProps {
  createdAt: number;
  inputs: TInputResult[];
  user?: TUser;
  contentID: string;
  title: string;
  getHistoryForPage: (
    page: number,
    limit: number,
  ) => Promise<{
    history: THistory[];
    total: number;
  }>;
}

const modelInfoColumns = [
  {
    Header: 'Architecture',
    accessor: 'architecture',
  },
  {
    Header: 'Framework',
    accessor: 'framework',
  },
  {
    Header: 'Framework Version',
    accessor: 'framework_version',
  },
  {
    Header: 'Language',
    accessor: 'language',
  },
];

const RowSubComponent = ({ row }: { row: any }) => {
  const historyObj = row.original as THistory;
  const dataSets = historyObj.inputs.filter(i => i.type === 'dataset');
  const models = historyObj.inputs.filter(i => i.type === 'model');
  const [datasetsSelectedColumn, setDatasetsSelectedColumn] = useState(
    dataSets.map(ds => {
      return Object.keys(ds.metadata.schema)[0];
    }),
  );

  return (
    <VStack
      bg={useColorModeValue('white', 'neutral.900')}
      p={2}
      alignItems={'flex-start'}
      w={'full'}
      borderWidth={'0 4px 4px 4px'}
      borderStyle={'solid'}
      borderColor={useColorModeValue(
        'var(--chakra-colors-brand-25)',
        'var(--chakra-colors-brand-850)',
      )}
      outline={'1px solid'}
      outlineColor={useColorModeValue(
        'var(--chakra-colors-brand-25)',
        'var(--chakra-colors-brand-850)',
      )}
    >
      <Tabs size="sm" colorScheme="brand" w={'full'}>
        {models.length > 0 && dataSets.length > 0 && (
          <TabList>
            {models.length > 0 && <Tab>Model Information</Tab>}
            {dataSets.length > 0 && <Tab>Dataset Information</Tab>}
          </TabList>
        )}

        <TabPanels>
          {models.length > 0 && (
            <TabPanel>
              <VStack alignItems={'flex-start'} gap={10}>
                {models.map(model => {
                  return (
                    <VStack w={'full'} alignItems={'flex-start'} gap={2}>
                      <Heading as={'h5'}>{model.name}</Heading>
                      <DataTable
                        columns={modelInfoColumns}
                        data={[model.metadata]}
                        isInteractive={false}
                        getHeaderProps={(column: any) => {
                          return {
                            style: {
                              padding: '0.5rem',
                              fontFamily: 'inherit',
                              textTransform: 'uppercase',
                              fontSize: '0.75rem',
                            },
                          };
                        }}
                        getCellProps={(cellInfo: any) => {
                          return {
                            style: {
                              padding: '0.5rem',
                            },
                          };
                        }}
                      ></DataTable>
                    </VStack>
                  );
                })}
              </VStack>
            </TabPanel>
          )}
          {dataSets.length && (
            <TabPanel>
              {dataSets.map((ds, dsIndex) => {
                const dataSetIndeces = Object.keys(ds.metadata.schema);
                const dataSetHeaders = ds.metadata.description
                  .filter((desc: any) => {
                    return desc[datasetsSelectedColumn[dsIndex]] !== null;
                  })
                  .map((row: any) => {
                    return {
                      Header: row.index,
                      accessor: row.index,
                    };
                  });
                const attributeSelectorColumn = {
                  Header: `Attributes (${dataSetIndeces.length.toString()} Total)`,
                  accessor: 'index',
                  Cell: ({ value }: any) => {
                    return (
                      <FormControl>
                        <Select
                          id="attribute"
                          colorScheme="brand"
                          _focusVisible={{
                            outlineColor: 'brand.50',
                          }}
                          onChange={e => {
                            const newArr = [...datasetsSelectedColumn];
                            newArr[dsIndex] = e.target.value;
                            setDatasetsSelectedColumn(newArr);
                          }}
                          value={datasetsSelectedColumn[dsIndex]}
                        >
                          {dataSetIndeces.map(header => {
                            return (
                              <option value={header}>
                                {header} (
                                {ds.metadata.schema[header] === 'int64'
                                  ? 'Numerical'
                                  : 'Categorical'}
                                )
                              </option>
                            );
                          })}
                        </Select>
                      </FormControl>
                    );
                  },
                };
                dataSetHeaders.unshift(attributeSelectorColumn);
                const getDataSetInfo = (field: string) => {
                  const data = ds.metadata.description.map((row: any) => {
                    const rowIndex = row.index;
                    return {
                      [rowIndex]: row[field],
                    };
                  });
                  const singleObject = Object.assign({}, ...data);
                  return [singleObject];
                };
                return (
                  <VStack w={'full'} alignItems={'flex-start'} gap={2} mb={2}>
                    <Heading as={'h5'}>{ds.name}</Heading>
                    <DataTable
                      columns={dataSetHeaders}
                      data={getDataSetInfo(datasetsSelectedColumn[dsIndex])}
                      getHeaderProps={(column: any) => {
                        return {
                          style: {
                            padding: '0.5rem',
                            fontFamily: 'inherit',
                            textTransform: 'uppercase',
                            fontSize: '0.75rem',
                          },
                        };
                      }}
                      getCellProps={(cellInfo: any) => {
                        return {
                          style: {
                            padding: '0.5rem',
                          },
                        };
                      }}
                    ></DataTable>
                  </VStack>
                );
              })}
            </TabPanel>
          )}
        </TabPanels>
      </Tabs>
    </VStack>
  );
};

export default function InputInfoModal({
  createdAt,
  inputs,
  user,
  contentID,
  title,
  getHistoryForPage,
}: MetricInputInfoModalProps) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [history, setHistory] = useState<THistory[]>([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [total, setTotal] = useState(0);

  const { isFetching: isFetchingHistory, refetch: refetchHistory } = useQuery(
    ['input', 'history', contentID],
    async () => {
      const results = await getHistoryForPage(page, limit);
      setHistory(results.history);
      setTotal(results.total);
    },
    {
      enabled: isOpen,
    },
  );

  useEffect(() => {
    refetchHistory();
  }, [page]);

  const historyColumns = [
    {
      Header: '',
      accessor: 'index',
      Cell: ({ row }: any) => {
        return (
          page === 1 &&
          row.index === 0 && (
            <Tag size={'sm'} variant="solid" colorScheme="green">
              Active
            </Tag>
          )
        );
      },
    },
    {
      Header: 'Date run',
      accessor: 'created_at',
      Cell: ({ value }: any) => {
        return (
          <Text textAlign={'left'}>{displayFormatedDateAndTime(value)}</Text>
        );
      },
    },
    {
      Header: 'by',
      accessor: 'user',
      Cell: ({ value }: any) => {
        if (!value) {
          return <></>;
        }
        return (
          <Tag key={value.cuid} size={'md'} borderRadius="full">
            <AvatarProxy
              src={value.picture}
              size="xs"
              name={value.name}
              ml={-1}
              mr={2}
            />
            <TagLabel>{value.name}</TagLabel>
          </Tag>
        );
      },
    },
    {
      id: 'models',
      Header: 'Models',
      accessor: 'inputs',
      Cell: ({ value }: { value: TInputResult[] }) => {
        let text = '';
        if (value) {
          text = value
            .filter(i => i.type === 'model')
            .map(i => i.name)
            .join(', ');
        }
        return <Text fontFamily={'monospace'}>{text}</Text>;
      },
    },
    {
      id: 'datasets',
      Header: 'Datasets',
      accessor: 'inputs',
      Cell: ({ value }: { value: TInputResult[] }) => {
        let text = '';
        if (value) {
          text = value
            .filter(i => i.type === 'dataset')
            .map(i => i.name)
            .join(', ');
        }
        return <Text fontFamily={'monospace'}>{text}</Text>;
      },
    },
    {
      id: 'expander',
      size: 1,
      Header: '',
      Cell: ({ row }: any) => {
        const historyObject = row.original as THistory;
        const hasData = historyObject.inputs.length > 0;
        if (!hasData) {
          return <></>;
        }
        return (
          <Box textAlign={'right'}>
            {row.isExpanded ? (
              <Icon as={ChevronUpIcon} boxSize={6} />
            ) : (
              <Icon as={ChevronDownIcon} boxSize={6} />
            )}
          </Box>
        );
      },
    },
  ];

  const latestDateFormatted = displayFormatedDateAndTime(createdAt);

  const latestDatasets = inputs
    .filter(i => i.type === 'dataset')
    .map(i => i.name);

  const latestModels = inputs.filter(i => i.type === 'model').map(i => i.name);

  return (
    <>
      <Stack alignItems={'flex-end'} mb={8}>
        <Popover placement="left" trigger="hover" openDelay={1000}>
          <PopoverTrigger>
            <Button
              fontWeight={'normal'}
              variant={'ghost'}
              color={'neutral.500'}
              backgroundColor="transparent"
              rounded="md"
              _hover={{ bg: useColorModeValue('neutral.100', 'neutral.850') }}
              onClick={(e: any) => {
                onOpen();
                e.stopPropagation();
              }}
              whiteSpace={'break-spaces'}
            >
              <HStack gap={4}>
                <HStack gap={1}>
                  <Icon as={CalendarIcon} boxSize={5} />
                  <Text>{`${latestDateFormatted} (Latest)`}</Text>
                </HStack>
                {latestDatasets.length > 0 && (
                  <HStack gap={1}>
                    <Icon as={CircleStackIcon} boxSize={5} />
                    <Text
                      fontFamily={'monospace'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      maxW={'200px'}
                    >
                      {latestDatasets.join(', ')}
                    </Text>
                  </HStack>
                )}
                {latestModels.length > 0 && (
                  <HStack gap={1}>
                    <Icon as={CubeIcon} boxSize={5} />
                    {/* <Text fontWeight={'bold'}>Model:</Text> */}
                    <Text
                      fontFamily={'monospace'}
                      textOverflow={'ellipsis'}
                      overflow={'hidden'}
                      whiteSpace={'nowrap'}
                      maxW={'200px'}
                    >
                      {latestModels.join(', ')}
                    </Text>
                  </HStack>
                )}
                {user && (
                  <HStack gap={1}>
                    <Tag key={'id'} size={'md'} borderRadius="full">
                      <AvatarProxy
                        src={user.picture}
                        size="xs"
                        name={user.name}
                        ml={-2}
                        mr={2}
                      />
                      <TagLabel>{user.name}</TagLabel>
                    </Tag>
                  </HStack>
                )}
              </HStack>
            </Button>
          </PopoverTrigger>
          <PopoverContent
            bg={useColorModeValue('neutral.100', 'neutral.800')}
            borderColor={useColorModeValue('neutral.300', 'neutral.600')}
            rounded={'md'}
            shadow={'xl'}
          >
            <PopoverArrow
              bg={useColorModeValue('neutral.100', 'neutral.800')}
              boxShadow={'0 0 0 1px var(--chakra-colors-neutral-300)'}
            />

            <PopoverBody rounded={'md'} p={4}>
              <VStack gap={4} alignItems={'flex-start'}>
                <HStack gap={1}>
                  <Icon as={CalendarIcon} boxSize={5} />
                  <Text>{`${latestDateFormatted} (Latest)`}</Text>
                </HStack>
                {latestDatasets.length > 0 && (
                  <HStack gap={1} alignItems={'flex-start'}>
                    <Icon as={CircleStackIcon} boxSize={5} />
                    <Text fontFamily={'monospace'} fontSize={'md'}>
                      {latestDatasets.join(', ')}
                    </Text>
                  </HStack>
                )}
                {latestModels.length > 0 && (
                  <HStack gap={1} alignItems={'flex-start'}>
                    <Icon as={CubeIcon} boxSize={5} />
                    <Text fontFamily={'monospace'} fontSize={'md'}>
                      {latestModels.join(', ')}
                    </Text>
                  </HStack>
                )}
                <HStack gap={1}>
                  <Tag key={'id'} size={'md'} borderRadius="full">
                    <AvatarProxy
                      src={user?.picture}
                      size="xs"
                      name={user?.name || 'Unknown'}
                      ml={-2}
                      mr={2}
                    />
                    <TagLabel>{user?.name || 'Unknown'}</TagLabel>
                  </Tag>
                </HStack>
              </VStack>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Stack>

      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={'6xl'}
        blockScrollOnMount={true}
        isCentered
        motionPreset="slideInBottom"
        scrollBehavior="inside"
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Heading as={'h5'} mb={6}>
              Timeline
            </Heading>
            {isFetchingHistory && (
              <Center p={12}>
                <Stack justify={'center'} align={'center'}>
                  <CircularProgress
                    size="40px"
                    thickness="2px"
                    isIndeterminate
                    color="brand.base"
                  />
                  <Text fontSize={'sm'} color={'inherit'}>
                    Please hold...
                  </Text>
                </Stack>
              </Center>
            )}
            {!isFetchingHistory && (
              <>
                <DataTable
                  columns={historyColumns}
                  data={history}
                  isInteractive={true}
                  onClickRow={(row: any) => {
                    const historyObject = row.original as THistory;
                    const hasData = historyObject.inputs.length > 0;
                    if (hasData) {
                      row.toggleRowExpanded();
                    }
                  }}
                  getRowProps={(row: any) => {
                    return row.isExpanded
                      ? {
                          style: {
                            borderBottom: 'none',
                            borderTop:
                              '2px solid var(--chakra-colors-brand-50)',
                            background: useColorModeValue(
                              'var(--chakra-colors-brand-25)',
                              'var(--chakra-colors-brand-850)',
                            ),
                          },
                        }
                      : {
                          style: {},
                        };
                  }}
                  renderRowSubComponent={(props: any) => (
                    <RowSubComponent {...props} />
                  )}
                />
                {history.length > 0 && (
                  <Center mt={5}>
                    <HStack>
                      <Button
                        leftIcon={<Icon as={ArrowLongLeftIcon} boxSize={5} />}
                        onClick={() => setPage(page - 1)}
                        visibility={page === 1 ? 'hidden' : 'visible'}
                        disabled={page === 1}
                      >
                        Prev
                      </Button>
                      <Text fontSize={'sm'}>
                        Showing {(page - 1) * limit + 1} to{' '}
                        {Math.min(page * limit, total)} of {total}
                      </Text>
                      <Button
                        rightIcon={<Icon as={ArrowLongRightIcon} boxSize={5} />}
                        onClick={() => {
                          setPage(page + 1);
                        }}
                        visibility={
                          total / limit <= page ? 'hidden' : 'visible'
                        }
                        disabled={total / limit <= page}
                      >
                        Next
                      </Button>
                    </HStack>
                  </Center>
                )}
              </>
            )}
          </ModalBody>
          <ModalFooter>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
            <Spacer />
            {/* <Button onClick={onClose}>Swap metric sources</Button> */}
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
