import { ContentPageH2, Label, MainContentArea } from '../../components/Layout';
import ContentContainer from '../../components/Layout/ContentContainer';
import { SubsectionHeader } from '../../components/SubsectionHeader';
import {
  Flex,
  FormControl,
  Image,
  Select,
  Text,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import API, { TFigure, TMetricResult, TTestResult } from '../../api/API';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useAuth0 } from '@auth0/auth0-react';
import { useFigures } from '../../hooks/useFigure';
import { MetricContent } from '../../components/Templates/ModelDocumentation/MetricContent';

const FigureDisplay = ({ figure }: { figure?: TFigure }) => {
  return (
    <VStack gap={4}>
      <ContentPageH2 mb={4}>Figure: {figure?.key}</ContentPageH2>
      <Image src={figure?.url} boxSize="100%" />
    </VStack>
  );
};

export function DataExploration() {
  const { id } = useParams();
  const { getAccessTokenSilently } = useAuth0();
  const [objectType, setObjectType] = useState<string | undefined>();
  const [currentMetric, setCurrentMetric] = useState<
    TMetricResult | undefined
  >();
  const [currentTestResult, setCurrentTestResult] = useState<
    TTestResult | undefined
  >();
  const [currentFigure, setCurrentFigure] = useState<TFigure | undefined>();

  const { data: metrics, refetch: refetchMetrics } = useQuery(
    ['metrics', id],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetMetricsForProject(id!, accessToken);
    },
    {
      enabled: false,
      initialData: () => [] as TMetricResult[],
    },
  );

  const { data: testResults, refetch: refetchTestResults } = useQuery(
    ['test-results', id],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetTestResults(id!, accessToken);
    },
    {
      enabled: false,
      initialData: () => [] as TTestResult[],
    },
  );

  const { figures, refetch: refetchFigures } = useFigures();

  const sortedFigures = figures?.sort((a, b) => {
    if (a.key < b.key) {
      return -1;
    } else if (a.key > b.key) {
      return 1;
    } else {
      return 0;
    }
  });

  const setCurrentMetricFromCuid = (cuid: string) => {
    setCurrentMetric(metrics?.find(m => m.cuid === cuid));
  };

  const setCurrentTestResultFromCuid = (cuid: string) => {
    setCurrentTestResult(testResults?.find(t => t.cuid === cuid));
  };

  const setCurrentFigureFromCuid = (cuid: string) => {
    setCurrentFigure(figures?.find(f => f.cuid === cuid));
  };

  const queryObjects = (objectType: string) => {
    switch (objectType) {
      case 'metric':
        refetchMetrics();
        break;
      case 'testResult':
        refetchTestResults();
        break;
      case 'figure':
        refetchFigures();
        break;
    }
    setObjectType(objectType);
  };

  return (
    <>
      <MainContentArea gap={4}>
        <SubsectionHeader pageTitle="Data Exploration" />
        <ContentContainer>
          <Text>
            In this page, we display the data we have collected with the
            ValidMind developer framework and allow users to explore and analyze
            it. They will also be able to specify the way this data is presented
            in the model documentation and validation report.
          </Text>
          <Flex
            w="100%"
            bg="white"
            rounded="lg"
            p={8}
            border="1px"
            borderColor="neutral.100"
            gap={8}
          >
            <VStack gap={4} w="33%">
              <FormControl>
                <Label>Object Type</Label>
                <Select
                  placeholder="Select object type"
                  bg="white"
                  value={objectType}
                  onChange={e => queryObjects(e.target.value)}
                >
                  <option value="metric">Metric</option>
                  <option value="testResult">Test Result</option>
                  <option value="figure">Figure</option>
                </Select>
              </FormControl>
              {objectType === 'metric' && (
                <FormControl>
                  <Label>Metrics</Label>
                  <Select
                    placeholder="Select metric"
                    bg="white"
                    onChange={e => setCurrentMetricFromCuid(e.target.value)}
                  >
                    {metrics?.map(metric => (
                      <option key={metric.key} value={metric.cuid}>
                        {metric.key}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {objectType === 'testResult' && (
                <FormControl>
                  <Label>Test Results</Label>
                  <Select
                    placeholder="Select test result"
                    bg="white"
                    onChange={e => setCurrentTestResultFromCuid(e.target.value)}
                  >
                    {testResults?.map(testResult => (
                      <option
                        key={testResult.test_name}
                        value={testResult.cuid}
                      >
                        {testResult.test_name}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
              {objectType === 'figure' && (
                <FormControl>
                  <Label>Figures</Label>
                  <Select
                    placeholder="Select figure"
                    bg="white"
                    onChange={e => setCurrentFigureFromCuid(e.target.value)}
                  >
                    {sortedFigures?.map(figure => (
                      <option key={figure.key} value={figure.cuid}>
                        {figure.key}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            </VStack>
            <VStack gap={4} width="66%">
              {objectType === 'figure' && currentFigure && (
                <FigureDisplay figure={currentFigure} />
              )}
              {objectType === 'metric' && currentMetric && (
                // Make metric content take metric as-is too
                <MetricContent
                  contents={{
                    content_id: currentMetric.key,
                    content_type: 'metric',
                    options: { content_id: currentMetric.key },
                  }}
                />
              )}
              {objectType === 'testResult' && currentTestResult && (
                <div>Fix this.</div>
              )}
            </VStack>
          </Flex>
        </ContentContainer>
      </MainContentArea>
    </>
  );
}
