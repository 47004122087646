import { useCallback, useEffect, useMemo } from 'react';
import {
  CustomFieldJSONSchema,
  CustomFieldProps,
  CustomFieldTypes,
} from '../types';
import CustomFieldValidator, { ValidatorKey } from '../CustomFieldValidator';
import CustomText from '../CustomText';
import CustomSwitch from '../CustomSwitch';
import CustomSelect from '../CustomSelect';
import CustomUserSelect from '../CustomUserSelect';
import CustomDatePicker from '../CustomDatePicker';
import React from 'react';
import CustomAutoComplete from '../CustomAutoComplete';
import CustomCodeResult from '../CustomCodeResult';
import CustomNumber from '../CustomNumber';

const NotImplementedComponent = () => {
  return <div>Model inventory field not implemented</div>;
};

type TypeIdMapType = {
  [key in CustomFieldTypes]: {
    component: React.FC<CustomFieldProps>;
    validators: ValidatorKey[];
  };
};

const TypeIdMap: TypeIdMapType = {
  'model-user': {
    component: CustomUserSelect,
    validators: [],
  },
  multiple: {
    component: CustomSelect,
    validators: [],
  },
  'date-time': {
    component: CustomDatePicker,
    validators: ['date:is-unix-string'],
  },
  date: {
    component: CustomDatePicker,
    validators: ['date:is-unix-string'],
  },
  'single-line': {
    component: CustomText,
    validators: [],
  },
  uri: {
    component: CustomText,
    validators: ['text:url'],
  },
  number: {
    component: CustomNumber,
    validators: ['number'],
  },
  'single-select': {
    component: CustomSelect,
    validators: [],
  },
  email: {
    component: CustomText,
    validators: ['text:email'],
  },
  checkbox: {
    component: CustomSwitch,
    validators: [],
  },
  'multi-line': {
    component: CustomText,
    validators: [],
  },
  'auto-complete': {
    component: CustomAutoComplete,
    validators: [],
  },
  'code-python': {
    component: CustomCodeResult,
    validators: [],
  },
};

interface CustomFieldRendererProps extends CustomFieldProps {
  schema: CustomFieldJSONSchema;
  onError?: (error: string[]) => void;
}

const CustomFieldRenderer = React.forwardRef(
  (props: CustomFieldRendererProps, ref) => {
    const typeId = props.schema.type;
    const isRequired = props.schema.isRequired;
    const mappedObject = TypeIdMap[typeId] || {
      component: NotImplementedComponent,
      validators: [],
    };

    useEffect(() => {
      if (props.mode === 'display' || props.mode === 'read-only') {
        props.onError?.([]);
      }
    }, [props.mode]);

    const validationFunctions = useMemo(() => {
      const validator = CustomFieldValidator({
        fieldName: props.schema.label,
      });
      let functions = [];

      if (isRequired) {
        functions.push(validator['any:required']);
      }

      functions = functions.concat(
        mappedObject.validators.map(validatorKey => validator[validatorKey]),
      );

      return functions;
    }, [mappedObject.validators, isRequired]);

    const validate = useCallback(
      (valueToValidate?: any) => {
        const value =
          valueToValidate === undefined ? props.value : valueToValidate;
        const validationErrors: string[] = [];
        validationFunctions.forEach(fn => {
          const error = fn({ value });
          if (error) {
            validationErrors.push(error);
          }
        });
        return validationErrors;
      },
      [validationFunctions, props.value],
    );

    // Expose validate through the ref passed from the parent
    React.useImperativeHandle(ref, () => ({
      validate,
    }));

    const onChangeWithValidation = (data: any) => {
      const errors = validate(data);
      props.onError?.(errors);
      props.onChange(data);
    };

    return (
      <mappedObject.component
        schema={props.schema}
        onChange={onChangeWithValidation}
        value={props.value}
        mode={props.mode}
      />
    );
  },
);

export default CustomFieldRenderer;
