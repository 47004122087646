import { defineStyleConfig } from '@chakra-ui/react';

export const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 'bold',
    borderRadius: 'base',
    _focus: {
      outline: 'none',
      boxShadow: '0 0 0 1px var(--chakra-colors-brand-400)',
    },
  },

  variants: {
    outline: {
      bg: 'white',
      color: 'secondaryBrand.800',
      borderColor: 'secondaryBrand.500',
      _dark: {
        borderColor: 'neutral.500',
        bg: 'neutral.950',
        color: 'neutral.200',
      },
      _hover: {
        bg: 'secondaryBrand.50',
        color: 'secondaryBrand.600',
      },
    },
    primary: {
      bg: 'secondaryBrand.base',
      color: 'white',
      _hover: {
        bg: 'secondaryBrand.500',
        color: 'neutral.50',
        _disabled: {
          bg: 'secondaryBrand.500',
        },
      },
      _dark: {
        bg: 'darkSecondaryBrand.base',
        color: 'neutral.100',
        _hover: {
          bg: 'darkSecondaryBrand.500',
          color: 'neutral.200',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
    secondary: {
      bg: 'neutral.100',
      color: 'neutral.900',
      _hover: {
        bg: 'neutral.200',
        color: 'neutral.800',
      },
      _dark: {
        bg: 'neutral.850',
        color: 'neutral.100',
        _hover: {
          bg: 'neutral.800',
          color: 'neutral.200',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
    tertiary: {
      bg: 'neutral.100',
      color: 'black',
      _hover: {
        bg: 'neutral.200',
        color: 'neutral.800',
      },
      _dark: {
        bg: 'neutral.850',
        color: 'neutral.100',
        _hover: {
          bg: 'neutral.800',
          color: 'neutral.200',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
    ghost: {
      bg: 'transparent',
      color: 'secondaryBrand.600',
      _hover: {
        bg: 'secondaryBrand.50',
        color: 'secondaryBrand.600',
      },
      _dark: {
        bg: 'transparent',
        color: 'secondaryBrand.400',
        _hover: {
          bg: 'secondaryBrand.900',
          color: 'secondaryBrand.500',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
    link: {
      color: 'secondaryBrand.600',
      _hover: {
        color: 'secondaryBrand.600',
      },
      _dark: {
        bg: 'transparent',
        color: 'secondaryBrand.400',
        _hover: {
          color: 'secondaryBrand.500',
        },
        _focus: {
          borderColor: 'brand.400',
          boxShadow: '0 0 0 1px brand.400',
        },
      },
    },
  },
  defaultProps: {
    variant: 'secondary',
  },
});
