import React from 'react';
import { HStack, Button } from '@chakra-ui/react';

export interface CustomFieldEditLayoutProps {
  children: React.ReactNode;
  onSaveClick: any;
  onCancelClick: any;
  showEditControls?: any;
}

const CustomFieldEditLayout = ({
  children,
  onSaveClick,
  onCancelClick,
  showEditControls,
}: CustomFieldEditLayoutProps) => {
  return (
    <>
      {children}
      {!showEditControls ? (
        <HStack alignSelf={'flex-end'}>
          <Button onClick={onCancelClick} variant={'ghost'}>
            Cancel
          </Button>

          <Button type="submit" variant={'primary'} onClick={onSaveClick}>
            Save
          </Button>
        </HStack>
      ) : (
        <></>
      )}
    </>
  );
};

export default CustomFieldEditLayout;
