import {
  Button,
  CloseButton,
  Divider,
  FormControl,
  FormHelperText,
  Heading,
  HStack,
  Input,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Spacer,
  Stack,
  Text,
  Textarea,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import _ from 'lodash';
import { UsersContext } from '../../../../../../contexts';
import ConfirmationAlert from '../../../../../../components/ConfirmationAlert';
import useWorkflow from '../../../../../../hooks/useWorkflow';
import { Label } from '../../../../../../components/Layout';
import { useOrgRoles } from '../../../../../../hooks/useOrgRoles';
import { ApprovalNodeType } from '../../types';
import QueryBuilder, {
  Field,
  RuleGroupType,
  RuleOrGroupArray,
} from 'react-querybuilder';
import useQueryBuilder from '../../../hooks/useQueryBuilder';
import { formatQuery } from 'react-querybuilder/formatQuery';
import { parseJsonLogic } from 'react-querybuilder/parseJsonLogic';

interface ApprovalPanelProps {
  node: ApprovalNodeType;
  onAddNode: (node: ApprovalNodeType) => void;
  onDeleteNode: (node: ApprovalNodeType) => void;
}

const ApprovalPanel = ({
  node,
  onAddNode,
  onDeleteNode,
}: ApprovalPanelProps) => {
  const { currentOrganization } = useContext(UsersContext);
  const { setSelectedNodeId } = useWorkflow();
  const [tempNode, setTempNode] = useState<ApprovalNodeType>(node);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const { orgRoles, isLoading: isRolesLoading } = useOrgRoles();
  const { query, setQuery, defaultQuery } = useQueryBuilder();

  useEffect(() => {
    setTempNode(node);
    if (node && tempNode.data.state_callbacks.on_enter[0].args.voters) {
      setQuery(
        parseJsonLogic(
          tempNode.data.state_callbacks.on_enter[0].args.voters,
        ) as RuleGroupType,
      );
    } else {
      setQuery(defaultQuery);
    }
  }, [node]);

  const votersQueryFields = useMemo(() => {
    let fields: Field[] = [
      {
        name: 'user.roles',
        label: 'User Roles',
        operators: [{ name: '=', value: '=', label: 'is' } as const],
        valueEditorType: 'select',
        values: orgRoles.map(role => ({
          name: role.cuid,
          label: role.name,
        })),
      },
    ];

    const customFields =
      currentOrganization?.inventory_model_schema.custom.settings.properties ||
      {};
    const userEntityKeys = Object.keys(customFields).filter(
      key => customFields[key as keyof object].entity === 'User',
    );

    fields.push({
      name: 'model.custom_fields',
      label: 'Model Inventory Fields',
      operators: [{ name: '=', value: '=', label: 'is' } as const],
      valueEditorType: 'select',
      values: userEntityKeys.map(key => ({
        name: key,
        label: customFields[key as keyof object].label,
      })),
    });

    return fields;
  }, [currentOrganization, orgRoles]);

  interface AddRuleActionProps {
    className?: string;
    handleOnClick: any;
    rules: RuleOrGroupArray;
  }

  const AddRuleAction = ({
    className,
    handleOnClick,
    rules,
  }: AddRuleActionProps) => {
    const isDisabled = rules.length >= 1;
    return (
      <Button
        size="xs"
        className={className}
        onClick={handleOnClick}
        disabled={isDisabled}
        isDisabled={isDisabled}
      >
        Add Rule
      </Button>
    );
  };

  useEffect(() => {
    setTempNode(node);
  }, [node]);

  const onThresholdChange = (val: number) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.vote_threshold = val;
      setTempNode(clonedNode);
    }
  };

  const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.title = e.target.value;
      setTempNode(clonedNode);
    }
  };

  const onMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (tempNode) {
      let clonedNode = _.cloneDeep(tempNode);
      clonedNode.data.state_callbacks.on_enter[0].args.message = e.target.value;
      setTempNode(clonedNode);
    }
  };

  const onSave = () => {
    if (tempNode) {
      const votersQuery = formatQuery(query, 'jsonlogic');
      tempNode.data.state_callbacks.on_enter[0].args.voters = votersQuery;

      onAddNode(tempNode);
      setSelectedNodeId!();
    }
  };
  const onConfirmDeleteStep = (confirmed: boolean) => {
    if (tempNode && confirmed) {
      onDeleteNode(tempNode);
    }
    setConfirmDelete(false);
  };

  const onClose = () => {
    setSelectedNodeId!();
  };

  return (
    <>
      <Stack
        bg={useColorModeValue('neutral.50', 'neutral.900')}
        border={'1px solid'}
        borderColor={useColorModeValue('neutral.200', 'neutral.800')}
        p={4}
        rounded={4}
        shadow={'lg'}
      >
        <VStack gap={4} alignItems={'flex-start'}>
          <HStack w={'full'}>
            <Heading as={'h4'}>Configure Approval Step</Heading>
            <Spacer />
            <CloseButton onClick={onClose} />
          </HStack>

          <FormControl>
            <Label mb={2}>Approval Group</Label>

            <Stack>
              <QueryBuilder
                fields={votersQueryFields}
                query={query}
                onQueryChange={setQuery}
                controlElements={{
                  addGroupAction: () => null,
                  combinatorSelector: () => null,
                  addRuleAction: props => (
                    <AddRuleAction {...props} rules={props.rules || []} />
                  ),
                }}
              />
            </Stack>
          </FormControl>

          <FormControl>
            <Label mb={2}>Threshold</Label>
            {/* <Input
            bg={'white'}
            size="sm"
            placeholder="Threshold"
            onChange={onThresholdChange}
            value={
              tempNode?.data.state_callbacks.on_enter[0].args.vote_threshold
            }
          /> */}
            <HStack gap={4}>
              <Slider
                value={
                  tempNode?.data.state_callbacks.on_enter[0].args.vote_threshold
                }
                aria-label="slider-ex-6"
                onChange={val => onThresholdChange(val)}
                step={10}
                // TODO: We can calculate this value depending on the number of users in the group.
                colorScheme="brand"
              >
                {/* <SliderMark value={25}>25%</SliderMark>
            <SliderMark value={50}>50%</SliderMark>
          <SliderMark value={75}>75%</SliderMark> */}

                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>

                <SliderThumb />
              </Slider>
              <Text>
                {tempNode?.data.state_callbacks.on_enter[0].args.vote_threshold}
                %
              </Text>
            </HStack>

            <FormHelperText fontSize={'sm'}>
              Percentage of approvers required for resource to be approved.
            </FormHelperText>
          </FormControl>
          <Divider />
          <VStack alignItems={'flex-start'}>
            <Heading as={'h4'}>Approval Message</Heading>
            <Text fontSize={'sm'} maxW={'prose'}>
              When your resource reaches this approval step, the users in the
              selected group will receive the following message.
            </Text>
          </VStack>
          <FormControl>
            <Label mb={2}>Title</Label>
            <Input
              bg={'white'}
              size="sm"
              placeholder="Title"
              onChange={onTitleChange}
              value={tempNode?.data.state_callbacks.on_enter[0].args.title}
            />
          </FormControl>

          <FormControl>
            <Label mb={2}>Message</Label>

            <Textarea
              bg={'white'}
              placeholder="Message"
              onChange={onMessageChange}
              value={tempNode?.data.state_callbacks.on_enter[0].args.message}
            />
          </FormControl>
          <HStack justifyContent={'space-between'} w={'full'}>
            <Button
              onClick={() => setConfirmDelete(true)}
              _hover={{
                bg: 'red.100',
                color: 'red.700',
              }}
            >
              Delete
            </Button>
            <Button onClick={onSave} variant={'primary'}>
              Save
            </Button>
          </HStack>
        </VStack>
      </Stack>
      <ConfirmationAlert
        title={`Deleting step`}
        dialogBody={'Are you sure?'}
        open={confirmDelete}
        onConfirm={onConfirmDeleteStep}
      />
    </>
  );
};

export default ApprovalPanel;
