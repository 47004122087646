import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Icon,
  useDisclosure,
  Checkbox,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  Box,
  Spacer,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { DataTable } from '../../../../../components/DataTable';
import {
  ArrowDownIcon,
  ArrowUpIcon,
  SquaresPlusIcon,
} from '@heroicons/react/24/outline';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import API from '../../../../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useMemo, useState } from 'react';
import {
  TInventoryModel,
  TInventoryModelDependency,
} from '../../../../../models/inventory_model';
import { MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS } from '../../../index';

export interface IDependencyManagerModalProps {
  inventoryModel: TInventoryModel;
  buttonVariant?: string;
}

export function DependencyManagerModal({
  inventoryModel,
  buttonVariant = 'primary',
}: IDependencyManagerModalProps) {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { getAccessTokenSilently } = useAuth0();
  const [models, setModels] = useState<TInventoryModel[]>([]);
  const [activeCategory, setActiveCategory] = useState<
    'upstream' | 'downstream'
  >('upstream');
  const [originalDownstream, setOriginalDownstream] = useState(
    inventoryModel.dependencies.filter(d => d.category === 'downstream'),
  );
  const [originalUpstream, setOriginalUpstream] = useState(
    inventoryModel.dependencies.filter(d => d.category === 'upstream'),
  );
  const [downstream, setDownstream] = useState<TInventoryModelDependency[]>([]);
  const [upstream, setUpstream] = useState<TInventoryModelDependency[]>([]);

  const { isLoading } = useQuery(
    ['inventory-models'],
    async () => {
      const accessToken = await getAccessTokenSilently();
      return await API.GetModelInventory(accessToken);
    },
    {
      onSuccess: data => {
        // exclude the current model from the list of models
        setModels(data.results.filter(m => m.cuid !== inventoryModel.cuid));
      },
    },
  );

  useEffect(() => {
    setUpstream(originalUpstream);
    setDownstream(originalDownstream);
  }, [originalDownstream, originalUpstream]);

  const onDependencyChange = (checked: boolean, model: TInventoryModel) => {
    if (checked) {
      const newDependency: TInventoryModelDependency = {
        cuid: '',
        category: activeCategory,
        depends_on: {
          cuid: model.cuid,
          name: model.name,
        },
        dependent: {
          cuid: inventoryModel.cuid,
          name: inventoryModel.name,
        },
      };

      if (activeCategory === 'upstream') {
        setUpstream([...upstream, newDependency]);
      } else {
        setDownstream([...downstream, newDependency]);
      }
    } else {
      if (activeCategory === 'upstream') {
        setUpstream(upstream.filter(d => d.depends_on.cuid !== model.cuid));
      } else {
        setDownstream(downstream.filter(d => d.depends_on.cuid !== model.cuid));
      }
    }
  };

  const postDependencies = useMutation(
    [],
    async () => {
      const accessToken = await getAccessTokenSilently();
      const dependencies = [...upstream, ...downstream];
      return await API.PostInventoryModelDependencies(
        accessToken,
        inventoryModel,
        dependencies,
      );
    },
    {
      onSuccess: data => {
        queryClient.invalidateQueries({
          queryKey: ['inventory-model', inventoryModel.cuid],
        });
        onClose();
      },
    },
  );

  const onSave = () => {
    postDependencies.mutate();
  };

  const tableColumns = useMemo(
    () => [
      {
        Header: ' ',
        accessor: '',
        Cell: ({ row }: any) => {
          return (
            <Checkbox
              size={'lg'}
              defaultChecked={
                activeCategory == 'upstream'
                  ? upstream.some(d => d.depends_on.cuid === row.original.cuid)
                  : downstream.some(
                      d => d.depends_on.cuid === row.original.cuid,
                    )
              }
            />
          );
        },
      },
      ...MODELS_TABLE_ALL_NON_CUSTOM_COLUMNS,
    ],
    [upstream, downstream, activeCategory],
  );

  return (
    <>
      <Button
        leftIcon={<Icon as={SquaresPlusIcon} boxSize={5} />}
        variant={buttonVariant}
        onClick={onOpen}
      >
        Manage Dependencies
      </Button>

      <Modal isCentered onClose={onClose} isOpen={isOpen} size={'6xl'}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Manage Model Interdependencies</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Tabs colorScheme="brand">
              <TabList>
                <Tab onClick={() => setActiveCategory('upstream')}>
                  <Icon as={ArrowUpIcon} boxSize={4} mr={2} />
                  Upstream
                </Tab>
                <Tab onClick={() => setActiveCategory('downstream')}>
                  <Icon as={ArrowDownIcon} boxSize={4} mr={2} />
                  Downstream
                </Tab>
              </TabList>

              <TabPanels p={4}>
                <Box
                  bg={useColorModeValue('white', 'neutral.950')}
                  rounded={'md'}
                >
                  <DataTable
                    isInteractive
                    enableSort
                    onClickRow={(row: any) => {
                      let cat = upstream;
                      if (activeCategory == 'downstream') {
                        cat = downstream;
                      }
                      onDependencyChange(
                        !cat
                          .map(d => d.depends_on.cuid)
                          .includes(row.original.cuid),
                        row.original,
                      );
                    }}
                    columns={tableColumns}
                    data={models}
                  />
                </Box>
              </TabPanels>
            </Tabs>
          </ModalBody>

          <ModalFooter>
            <Button
              variant="ghost"
              onClick={onClose}
              disabled={postDependencies.isLoading}
              data-testid="change-status-modal-cancel-btn"
            >
              Cancel
            </Button>
            <Spacer />
            <Button isLoading={postDependencies.isLoading} onClick={onSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
