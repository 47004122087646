import { useContext, useEffect, useState } from 'react';
import {
  Checkbox,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  Text,
  Button,
  useColorModeValue,
} from '@chakra-ui/react';
import { PlusIcon } from '@heroicons/react/24/outline';
import RiskAreaContext from '../../contexts/RiskAreaContext';
import { Guideline } from '../../models/guideline';

export interface GuidelinesModalProps {
  allGuidelines: Guideline[];
  isOpen: boolean;
  onClose: () => void;
  existingGuidelineContentIds?: string[];
  onSaved: (guidelines: Guideline[]) => void;
}

export default function GuidelinesModal({
  isOpen,
  onClose,
  existingGuidelineContentIds,
  allGuidelines,
  onSaved,
}: GuidelinesModalProps) {
  const { riskAreas } = useContext(RiskAreaContext);
  const [selectedGuidelines, setSelectedGuidelines] = useState<Guideline[]>([]);

  useEffect(() => {
    if (!isOpen) setSelectedGuidelines([]);
  }, [isOpen]);

  const guidelinesRiskAreasMap = allGuidelines.reduce((acc, guideline) => {
    const riskArea = riskAreas.find(
      item => item.cuid === guideline.risk_area?.cuid,
    );
    if (riskArea) {
      // This guideline is already in the section, so skip
      if (existingGuidelineContentIds?.includes(guideline.content_id)) {
        return acc;
      }
      if (!acc[riskArea.cuid]) {
        acc[riskArea.cuid] = [];
      }
      acc[riskArea.cuid].push(guideline);
    }
    return acc;
  }, {} as { [key: string]: Guideline[] });

  return (
    <Modal isCentered isOpen={isOpen} size="6xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent bg="white" maxH="90vh">
        <ModalHeader>Add Guidelines to Section</ModalHeader>
        <ModalCloseButton />
        <ModalBody overflowY="scroll">
          {Object.entries(guidelinesRiskAreasMap).map(
            ([riskAreaCuid, guidelines]) => {
              const riskArea = riskAreas.find(ra => ra.cuid === riskAreaCuid);
              const selectedGuidelinesInRiskArea = guidelines.filter(
                guideline =>
                  selectedGuidelines.some(sg => sg.cuid === guideline.cuid),
              );
              const riskAreaSelected =
                selectedGuidelinesInRiskArea.length === guidelines.length;
              const riskAreaIndeterminate =
                selectedGuidelinesInRiskArea.length > 0 &&
                selectedGuidelinesInRiskArea.length < guidelines.length;
              return (
                <VStack alignItems="flex-start" w="full" spacing={0}>
                  <HStack
                    spacing={4}
                    alignItems="center"
                    bg={useColorModeValue('neutral.50', 'neutral.850')}
                    borderBottom="1px solid"
                    borderColor={'var(--chakra-colors-chakra-border-color)'}
                    w="full"
                    paddingX={4}
                    paddingY={3}
                  >
                    <Checkbox
                      isChecked={riskAreaSelected}
                      isIndeterminate={riskAreaIndeterminate}
                      onChange={e => {
                        if (e.target.checked) {
                          setSelectedGuidelines(prev =>
                            prev.concat(guidelines),
                          );
                        } else {
                          setSelectedGuidelines(prev =>
                            prev.filter(
                              guideline => !guidelines.includes(guideline),
                            ),
                          );
                        }
                      }}
                    />
                    <HStack gap={2}>
                      <Heading as="h3" size="sm" lineHeight={'unset'}>
                        {riskArea?.name}
                      </Heading>
                    </HStack>
                  </HStack>
                  <VStack alignItems="flex-start" w="full" spacing={0}>
                    {guidelines.map((guideline, idx) => {
                      const guidelineSelected = selectedGuidelines.some(
                        sg => sg.cuid === guideline.cuid,
                      );
                      return (
                        <HStack
                          gap={4}
                          p={4}
                          borderBottom="1px solid"
                          borderColor={
                            'var(--chakra-colors-chakra-border-color)'
                          }
                          w="full"
                        >
                          <Checkbox
                            isChecked={guidelineSelected}
                            onChange={e => {
                              if (e.target.checked) {
                                setSelectedGuidelines(prev =>
                                  prev.concat(guideline),
                                );
                              } else {
                                setSelectedGuidelines(prev =>
                                  prev.filter(sg => sg.cuid !== guideline.cuid),
                                );
                              }
                            }}
                          />
                          <VStack gap={4} alignItems="flex-start">
                            <VStack gap={1} alignItems="flex-start">
                              <Heading as="h4" size="sm">
                                {guideline.title}
                              </Heading>
                              <Text>{guideline.description}</Text>
                            </VStack>
                          </VStack>
                        </HStack>
                      );
                    })}
                  </VStack>
                </VStack>
              );
            },
          )}
        </ModalBody>
        <ModalFooter justifyContent="flex-end">
          {selectedGuidelines.length > 0 && (
            <Button
              variant="ghost"
              leftIcon={<Icon as={PlusIcon} boxSize={5} />}
              onClick={() => {
                onSaved(selectedGuidelines);
                onClose();
              }}
            >
              Add {selectedGuidelines.length} Guideline
              {selectedGuidelines.length > 1 ? 's' : ''}
            </Button>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
