import axios, { AxiosError } from 'axios';
import { CONFIG } from '../config';
import { TAttachment } from '../models/attachment';
import { TFeatureFlag } from '../models/flags';
import {
  FindingFilters,
  TFinding,
  TFindingFiltersOptions,
  TFindingPaginated,
  TSeverity,
} from '../models/finding';
import {
  TProjectMetadata,
  TProjectMetadataContentIdEnum,
} from '../models/metadata';
import {
  TProject,
  TProjectFilters,
  TProjectsPaginated,
} from '../models/project';
import {
  OfflineDocumentationFile,
  Template,
  TemplateSection,
  TemplateSectionContents,
  TemplateValidation,
  TemplateVersion,
} from '../models/template';
import {
  TUser,
  TUserInvite,
  TUserWithoutRoles,
  UISettings,
} from '../models/user';
import {
  RolePatchRequest,
  TPermissionAction,
  TPermissionsOverview,
  TRolePermissions,
  TAssignedPermission,
  TRoleRequest,
  TUserRole,
  TUserRolePaginated,
} from '../models/role';
import {
  TUseCasePaginated,
  TUseCaseCategory,
  TCreateUseCaseRequest,
  TUseCase,
} from '../models/use_case';
import {
  APIPaginatedResponse,
  APIRequestSortBy,
  keyable,
} from '../models/utils';
import { TProjectSearchResult } from '../models/search';
import { TDatasetField } from '../models/dataset_field';
import { TRole } from '../models';
import {
  TStatusesWorkflow,
  TStatusesWorkflowStatus,
} from '../models/statuses_workflow';
import {
  RJSFSchemaSettings,
  RJSFSchemaSettingsProperty,
  TInventoryModel,
  TInventoryModelCustomField,
  TInventoryModelPaginated,
  TInventoryModelPost,
  TInventoryModelSchema,
  TInventoryModelUser,
  TInventoryModelUserPost,
  TModelInventoryFilters,
  TInventoryModelDocumentationSummary,
  TInventoryModelValidationReportSummary,
  TInventoryModelDependency,
  InventoryModelStage,
} from '../models/inventory_model';
import { EventFilters, TEventPaginated } from '../models/event';
import {
  TBusinessUnit,
  TCreateBusinessUnitRequest,
} from '../models/business_unit';
import {
  CommentsAdapterThreadAdded,
  TrackChangesAdapterSuggestionAdded,
  TrackChangesAdapterSuggestionUpdated,
  TSuggestion,
} from '../models/ckeditor';
import { TGroup, TGroupMembership } from '../models/group';
import { ModelDocument, ModelDocumentTypeEnum } from '../models/model_document';
import {
  Assessment,
  AssessmentOption,
  AssessmentOptionWithSummary,
  Guideline,
  GuidelineDependencies,
  GuidelineRequest,
} from '../models/guideline';
import { SchemaPropertyItem } from '../models/json_schemas';
import { RJSFSchema } from '@rjsf/utils';
import { JSONSchema7 } from 'json-schema';
import {
  TAddRiskAreaRequest,
  TRiskArea,
  TRiskAreaDependencies,
} from '../models/risk_area';
import { TReport } from '../models/report';
import {
  ExecutionResponse,
  Workflow,
  WorkflowPaginated,
  WorkflowStatusForm,
} from '../models/workflow';
import { FieldValues } from '../hooks/useFields';
import { ApprovalVote, ApprovalVoter } from '../models/approval';
import { CodeTestResponse } from '../models/custom_field_code';
import { EntityAttachment, EntityType } from '../models/entity_attachments';
import { TUnitMetric } from '../models/unit_metric';

export type TProjectAnnotation = {
  json: keyable;
  target: {
    cuid: string;
    type: string;
  };
  cuid?: string;
  created_at?: string;
  updated_at?: string;
  user?: TUser;
  comments?: TProjectAnnotationComment[];
  context: {
    type: string;
    value: string;
  };
};

export type TProjectAnnotationCKEditor = {
  threadId: string;
  context: keyable;
  attributes: keyable;
  resolvedAt: string | Date | null;
  resolvedBy: string | null;
  comments: TProjectAnnotationCommentCKEditor[];
  createdAt: string | Date;
};

export type TProjectAnnotationPaginated =
  APIPaginatedResponse<TProjectAnnotation>;

export type TProjectAnnotationComment = {
  text: string;
  cuid?: string;
  created_at?: string;
  updated_at?: string;
  user?: TUser;
  parent_cuid?: string | null;
  path?: string;
};

export type TProjectAnnotationCommentCKEditor = {
  attributes: keyable;
  createdAt: string | Date;
  content: string;
  authorId: string;
  commentId: string;
};

export type TFigure = {
  cuid: string;
  filename: string;
  key: string;
  metadata: keyable;
  type: string;
  url: string;
  json_url: string;
  created_at: number;
  updated_at: number;
};

export type TFieldStatistics = {
  count: number;
  histogram: any;
  // Will replace histogram
  histograms: any;
  missing: number;
  n_missing: number;
  name: string;
  type: string;
  mean: number;
  std: number;
  min: number;
  max: number;
  '25%': number;
  '50%': number;
  '75%': number;
};

export type TDatasetStatistics = {
  [field: string]: TFieldStatistics;
};

export type TFieldCorrelation = {
  field: string;
  value: number;
  p_value?: number;
};

export type TDatasetCorrelations = {
  pearson: [[TFieldCorrelation]];
};

export type TDataset = {
  type: string;
  correlations: TDatasetCorrelations;
  fields: TDatasetField[];
  shape?: {
    rows: number;
    columns: number;
  };
  statistics: TDatasetStatistics;
  targets: {
    target_column: string;
    description?: string;
    class_labels: object;
  };
};

export enum FeatureFlags {
  vmai_suggestions = 'vmai-suggestions',
  vmai_interpretations = 'vmai-interpretations',
}

export type TSubscription = {
  cuid: string;
  name: string;
  type: string;
};

export type TOrganization = {
  cuid: string;
  name: string;
  api_clients: [
    {
      api_key: string;
      api_secret: string;
    },
  ];
  feature_flags: keyable;
  default_group: TGroup;
  roles: TRole[];
  subscription: TSubscription;
  inventory_model_schema: {
    custom: TInventoryModelSchema;
    core: TInventoryModelSchema;
  };
};

export type TOrganizationConnection = {
  connection: string;
};

export type TSummaryItem = {
  type: string;
  data: any;
  metadata?: {
    title?: string;
  };
};

export type TInputResult = {
  id: number;
  cuid: string;
  name: string;
  type: 'model' | 'dataset';
  metadata: any;
  created_at: string;
  updated_at: string;
};

export type TMetricResult = {
  cuid: string;
  key: string;
  project_cuid: number;
  value: any;
  summary?: TSummaryItem[];
  ref_id?: string;
  inputs: TInputResult[];
  user?: TUser;
  content_type: ModelDocumentTypeEnum;
  created_at: number;
};

export type TMetricResultKey = {
  cuid: string;
  key: string;
};

export type TComment = {
  user: {
    cuid: string;
    name: string;
    email: string;
    picture: string;
  };
  created_at: number;
  cuid: number;
  section: string;
  text: string;
  title: string;
  item_cuid: string;
  item_type: string;
  attachments: TAttachment[];
  updated_at: number;
};

export type TSingleTestResult = {
  column?: string;
  passed?: boolean;
  values: any;
};

export type TTestResult = {
  cuid: string;
  test_name: string;
  passed: boolean;
  params: keyable;
  results: [TSingleTestResult];
  summary?: TSummaryItem[];
  created_at: number;
  ref_id?: string;
  inputs: TInputResult[];
  user?: TUser;
  content_type: ModelDocumentTypeEnum;
};

export type TTestResultKey = {
  cuid: string;
  test_name: string;
  passed: boolean;
};

export type TTest = {
  test_type: 'test' | 'metric';
  key: string;
  name: string;
  tag: string;
  models: string[];
  datasets: string[];
  created_at: string;
  user: TUser;
};

export type TFindingRequest = {
  title: string;
  description: string;
  risk_area_cuid: string;
  metadata?: {
    severity?: string;
  };
  severity?: number;
  due_at?: number;
  owner_cuid?: string;
  documentation_section_id: string | null;
};

export type TProjectChecksResponse = {
  exists: boolean;
};

type PagedResponse<T> = {
  limit: number;
  page: number;
  results: T[];
  total: number;
};

let getOrganization: () => TOrganization | null;

export const setOrganizationRetrievalMethod = (
  OrganizationRetrievalMethod: () => TOrganization | null,
) => {
  getOrganization = OrganizationRetrievalMethod;
};

const axiosInstance = axios.create({
  baseURL: `${CONFIG.REACT_APP_API_URL}`,
});

axiosInstance.interceptors.request.use(async config => {
  if (getOrganization) {
    let org = getOrganization();
    if (org) {
      config.headers === undefined
        ? (config.headers = {
            'X-Organization-Id': org.cuid,
          })
        : (config.headers['X-Organization-Id'] = org.cuid);
    }
  }
  return config;
});

const AuthHeaders = (accessToken: string) => ({
  Authorization: `Bearer ${accessToken}`,
});

const AuthOrgHeaders = (
  accessToken: string,
  organization: TOrganization | null,
) => {
  let orgHeader = organization != null ? organization.cuid : null;
  return {
    Authorization: `Bearer ${accessToken}`,
    'X-Organization-Id': `${orgHeader}`,
  };
};

const CreateProject = async (
  accessToken: string,
  project: TProject,
): Promise<TProject> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects`,
    {
      // key: project.key,
      type: project.type,
      name: project.name,
      inventory_model_id: project.inventory_model.cuid,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const CreateProjectChecks = async (
  accessToken: string,
  name: string,
): Promise<TProjectChecksResponse> => {
  const result = await axiosInstance.get(`/projects/checks`, {
    params: {
      name,
    },
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetCurrentUser = async (accessToken: string): Promise<TUser> => {
  const result = await axiosInstance.get('/current_user', {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PatchCurrentUserUISettings = async (
  accessToken: string,
  uiSettings: UISettings,
): Promise<TUser> => {
  const result = await axiosInstance.patch(
    `/users/ui-settings`,
    { ...uiSettings },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchUserOrganization = async (
  accessToken: string,
  currentUserId: string | undefined,
): Promise<TUser> => {
  const result = await axiosInstance.patch(
    `/users/${currentUserId}`,
    {},
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetOrganization = async (accessToken: string): Promise<TOrganization> => {
  const result = await axiosInstance.get(`/organization`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetOrganizationConnection = async (
  domain: string,
): Promise<TOrganizationConnection> => {
  const result = await axiosInstance.get(`/organization/sso?domain=${domain}`);
  return result.data;
};

const GetOrganizationAssessmentOptions = async (
  accessToken: string,
): Promise<AssessmentOption[]> => {
  const result = await axiosInstance.get(`/organizations/assessment-options`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetOrganizationUsers = async (
  accessToken: string,
): Promise<TUserWithoutRoles[]> => {
  const result = await axiosInstance.get('/organization/users', {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetOrganizationBusinessUnits = async (
  accessToken: string,
): Promise<TBusinessUnit[]> => {
  const result = await axiosInstance.get(`/organization/business-units`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PostOrganizationBusinessUnits = async (
  accessToken: string,
  businessUnit: TCreateBusinessUnitRequest,
): Promise<TBusinessUnit> => {
  const result = await axiosInstance.post(
    `/organization/business-units`,
    businessUnit,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteOrganizationBusinessUnit = async (
  accessToken: string,
  businessUnitCuid: string,
): Promise<TBusinessUnit> => {
  const result = await axiosInstance.delete(
    `/organization/business-units/${businessUnitCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchOrganizationBusinessUnit = async (
  accessToken: string,
  businessUnitCuid: string,
  newTitle: string,
): Promise<TBusinessUnit> => {
  const result = await axiosInstance.patch(
    `/organization/business-units/${businessUnitCuid}`,
    { name: newTitle },
    { headers: AuthHeaders(accessToken) },
  );
  return result.data;
};

const GetOrganizationRoles = async (accessToken: string): Promise<TRole[]> => {
  const result = await axiosInstance.get(`/organization/roles`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetProjects = async (
  accessToken: string,
  filters?: TProjectFilters,
  searchQuery?: string,
  page: number = 1,
  limit: number = 100,
  sortBy?: APIRequestSortBy,
): Promise<TProjectsPaginated> => {
  // prepare the get params to be added to the API url
  let params = '';

  let parsedParams: any = { page, limit };

  if (sortBy) {
    parsedParams = {
      ...parsedParams,
      sort_by: sortBy.field,
      sort_order: sortBy.order,
    };
  }

  // prepare the filters by converting some objects to key,value pairs
  if (filters) {
    parsedParams = {
      ...parsedParams,
      ...filters,
      models: filters.models?.map(model => model.id),
      status: filters.status?.map(s => s.cuid),
      users: filters.users?.map(user => user.cuid),
      developers: filters.developers?.map(user => user.cuid),
      owners: filters.owners?.map(user => user.cuid),
      validators: filters.validators?.map(user => user.cuid),
    };
  }

  if (searchQuery) {
    parsedParams = {
      ...parsedParams,
      q: searchQuery,
    };
  }

  // Remove fields with null, undefined, or empty arrays
  parsedParams = Object.fromEntries(
    Object.entries(parsedParams).filter(([_, value]) => {
      return (
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    }),
  );

  params = new URLSearchParams(parsedParams as keyof object).toString();

  const result = await axiosInstance.get(`/projects?${params}`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetProjectByModelId = async (
  accessToken: string,
  projectId: string,
): Promise<TProject> => {
  const result = await axiosInstance.get(
    `/projects/${projectId}?isByModelId=true`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetDocument = async (
  accessToken: string,
  project: TProject,
  documentType?: string,
): Promise<TProject> => {
  let url = `/inventory-models/${project.inventory_model.cuid}/document/${project.cuid}/${documentType}`;
  const result = await axiosInstance.get(url, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetProjectSearch = async (
  project: TProject,
  accessToken: string,
  query: string,
): Promise<TProjectSearchResult[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/search?q=${query}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetProjectMetadataByContentId = async (
  accessToken: string,
  project: TProject,
  contentId: TProjectMetadataContentIdEnum | string,
  contentType: string,
): Promise<TProjectMetadata> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/metadata/${contentId}`,
    {
      params: {
        content_type: contentType,
      },
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

export type AnnotationFilters = {
  projects?: string[];
  targets?: string[];
};

const GetAnnotations = async (
  accessToken: string,
  filters?: AnnotationFilters,
  page?: number,
  limit?: number,
): Promise<TProjectAnnotationPaginated> => {
  let params: any = { ...filters, page, limit };
  const result = await axiosInstance.get(`/annotations`, {
    params,
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PostProjectMetadata = async (
  project: TProject,
  documentType: ModelDocumentTypeEnum,
  metadata: TProjectMetadata,
  accessToken: string,
  silent?: boolean,
  isComment?: boolean,
): Promise<TProjectMetadata> => {
  delete metadata.created_at;
  delete metadata.updated_at;
  var url = `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/metadata/${documentType}`;
  if (isComment) {
    url = `${url}/for-comments`;
  }
  if (silent) {
    url = `${url}?silent=${silent}`;
  }
  const result = await axiosInstance.post(
    url,
    { ...metadata },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostProjectMetadataAttachment = async (
  projectId: string,
  accessToken: string,
  content_id: string,
  file: File,
): Promise<TProjectMetadata> => {
  // this method is not used in the codebase
  const formData = new FormData();
  formData.append(`attachments[]`, file);

  const result = await axiosInstance.post(
    `/projects/${projectId}/metadata/${content_id}/attachments`,
    formData,
    {
      headers: {
        ...AuthHeaders(accessToken),
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const GetProjectFigures = async (
  projectId: string,
  accessToken: string,
  metadata?: keyable,
): Promise<TFigure[]> => {
  const baseUrl = `${CONFIG.REACT_APP_API_URL}/projects/${projectId}/figures`;
  const url = metadata
    ? `${baseUrl}?metadata=${JSON.stringify(metadata)}&isByModelId=true`
    : `${baseUrl}?isByModelId=true`;
  const result = await axiosInstance.get(url, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetProjectFigureByKey = async (
  projectId: string,
  key: string,
  accessToken: string,
): Promise<TFigure> => {
  // this method is not used in the codebase
  const result = await axiosInstance.get(
    `/projects/${projectId}/figure/${key}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetEvents = async (
  accessToken: string,
  page: number = 1,
  limit: number = 50,
  filters?: EventFilters,
  sortBy?: APIRequestSortBy,
): Promise<TEventPaginated> => {
  let params: any = { ...filters, page, limit };
  if (sortBy) {
    params = { ...params, sort_by: sortBy.field, sort_order: sortBy.order };
  }
  const result = await axiosInstance.get('/events', {
    params,
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetFeatureFlags = async (
  accessToken: string,
  flags: any,
): Promise<TFeatureFlag[]> => {
  const result = await axiosInstance.post(
    `/flags`,
    {
      flags,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetMetricForProject = async (
  projectId: string,
  accessToken: string,
  key: string,
  contentType?: ModelDocumentTypeEnum,
): Promise<TMetricResult> => {
  const result = await axiosInstance.get(
    `/projects/${projectId}/metrics/${key}?isByModelId=true`,
    {
      headers: AuthHeaders(accessToken),
      params: {
        content_type: contentType,
      },
    },
  );
  return result.data;
};

const GetMetricHistoryForProject = async (
  projectId: string,
  accessToken: string,
  key: string,
  contentType?: ModelDocumentTypeEnum,
  page?: number,
  limit?: number,
): Promise<PagedResponse<TMetricResult>> => {
  const config = {
    headers: AuthHeaders(accessToken),
    params: {
      page,
      limit,
      content_type: contentType,
    },
  };
  const result = await axios.get(
    `${CONFIG.REACT_APP_API_URL}/projects/${projectId}/metrics/${key}/history`,
    config,
  );
  return result.data;
};

const GetMetricsForProject = async (
  projectId: string,
  accessToken: string,
): Promise<TMetricResult[]> => {
  const result = await axiosInstance.get(
    `/projects/${projectId}/metrics?type=all&isByModelId=true`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetCommentsForProject = async (
  project: TProject,
  accessToken: string,
  itemCuid?: string,
): Promise<TComment[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/comments?project_cuid=${project.cuid}&item_cuid=${itemCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetTestResults = async (
  projectId: string,
  accessToken: string,
): Promise<TTestResult[]> => {
  const result = await axiosInstance.get(
    `/projects/${projectId}/test_results?isByModelId=true`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetTestResultForProject = async (
  projectId: string,
  accessToken: string,
  name: string,
  contentType?: ModelDocumentTypeEnum,
): Promise<TTestResult> => {
  const result = await axiosInstance.get(
    `/projects/${projectId}/test_results/${name}?isByModelId=true`,
    {
      headers: AuthHeaders(accessToken),
      params: {
        content_type: contentType,
      },
    },
  );
  return result.data;
};

const GetTestResultHistoryForProject = async (
  projectId: string,
  accessToken: string,
  name: string,
  contentType?: ModelDocumentTypeEnum,
  page?: number,
  limit?: number,
): Promise<PagedResponse<TTestResult>> => {
  const config = {
    headers: AuthHeaders(accessToken),
    params: {
      page,
      limit,
      content_type: contentType,
    },
  };
  const result = await axios.get(
    `${CONFIG.REACT_APP_API_URL}/projects/${projectId}/test_results/${name}/history`,
    config,
  );
  return result.data;
};

const PostCommentForProject = async (
  project: TProject,
  accessToken: string,
  title: string,
  text: string,
  item_type: string,
  item_cuid: string,
  attachments?: File[],
): Promise<any> => {
  const formData = new FormData();
  formData.append('title', title);
  formData.append('text', text);
  formData.append('item_type', item_type);
  formData.append('item_cuid', item_cuid);

  if (attachments) {
    for (let i = 0; i < attachments.length; i++) {
      formData.append(`attachments[]`, attachments[i]);
    }
  }

  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/comments`,
    formData,
    {
      headers: {
        ...AuthHeaders(accessToken),
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const UpdateOrganization = async (
  accessToken: string,
  name: string,
): Promise<TOrganization> => {
  const result = await axiosInstance.put(
    `/organization`,
    {
      name,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetTemplates = async (
  accessToken: string,
  includeCopies?: boolean,
): Promise<Template[]> => {
  let params = {};
  if (includeCopies) {
    params = {
      include_copies: includeCopies,
    };
  }
  const result = await axiosInstance.get(`/templates`, {
    headers: AuthHeaders(accessToken),
    params,
  });
  return result.data;
};

const GetTemplateVersion = async (
  accessToken: string,
  templateId: string,
  versionId: string = 'latest',
): Promise<Template> => {
  const result = await axiosInstance.get(
    `/templates/${templateId}?version=${versionId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const SwapTemplate = async (
  accessToken: string,
  project: TProject,
  toTemplateId: string,
  toVersionId: string,
  notes: string,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
): Promise<Template> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/templates/${documentType}/latest/swap/${toTemplateId}/${toVersionId}`,
    { notes },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const SetTemplate = async (
  accessToken: string,
  project: TProject,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  templateCuid: string,
): Promise<Template> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/templates/${documentType}`,
    {
      template: templateCuid,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const UpdateTemplate = async (
  accessToken: string,
  templateId: string,
  templateType: 'model_documentation' | 'validation_report',
  description: string,
  json: any,
): Promise<Template> => {
  const result = await axiosInstance.put(
    `/templates/${templateId}?type=${templateType}`,
    {
      description,
      json,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const ValidateTemplate = async (
  accessToken: string,
  templateId: string,
  json: any,
): Promise<TemplateValidation> => {
  const result = await axiosInstance.post(
    `/templates/${templateId}/validate`,
    {
      json,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetFindingSeverities = async (
  accessToken: string,
): Promise<TSeverity[]> => {
  const result = await axiosInstance.get(`/findings/severities`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetFindings = async (
  accessToken: string,
  page: number = 1,
  limit: number = 100,
  filters?: FindingFilters,
  sortBy?: APIRequestSortBy,
): Promise<TFindingPaginated> => {
  const filterParams: any = {};

  if (filters) {
    Object.keys(filters).forEach(filterKey => {
      //@ts-ignore
      const filterGroup = filters[filterKey];
      if (Array.isArray(filterGroup)) {
        filterParams[filterKey] = filterGroup.join(',');
      } else if (typeof filterGroup === 'boolean') {
        filterParams[filterKey] = filterGroup.toString();
      }
    });
  }

  let params: any = { ...filterParams, page, limit };

  if (sortBy) {
    params = { ...params, sort_by: sortBy.field, sort_order: sortBy.order };
  }
  const result = await axiosInstance.get(`/findings`, {
    params,
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetFindingFilters = async (
  accessToken: string,
): Promise<TFindingFiltersOptions> => {
  let url = `/findings/filters`;
  const result = await axiosInstance.get(url, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetFinding = async (
  project: TProject,
  accessToken: string,
  findingCuid: string,
): Promise<TFinding> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/findings/${findingCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostFinding = async (
  project: TProject,
  accessToken: string,
  findingRequestBody: TFindingRequest,
): Promise<TFinding> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/findings`,
    findingRequestBody,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const UpdateFinding = async (
  project: TProject,
  accessToken: string,
  findingCuid: string,
  findingObject: keyable,
): Promise<TFinding> => {
  const result = await axiosInstance.put(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/findings/${findingCuid}`,
    findingObject,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteFinding = async (
  accessToken: string,
  projectCuid: string,
  inventoryModelCuid: string,
  findingCuid: string,
): Promise<TFinding> => {
  const result = await axiosInstance.delete(
    `/inventory-models/${inventoryModelCuid}/projects/${projectCuid}/findings/${findingCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostFindingAttachment = async (
  project: TProject,
  accessToken: string,
  findingCuid: string,
  file: File,
): Promise<TProjectMetadata> => {
  const formData = new FormData();
  formData.append(`attachments[]`, file);

  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}}/findings/${findingCuid}/attachments`,
    formData,
    {
      headers: {
        ...AuthHeaders(accessToken),
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const DownloadValidationReport = async (
  project: TProject,
  includeComments: boolean,
  includeActivityFeed: boolean,
  includeStatus: boolean,
  includeFindings: boolean,
  format: string,
  downloadFilename: string,
  accessToken: string,
): Promise<any> => {
  return await axiosInstance.get(
    `/reports/${project.inventory_model.cuid}/validation-report/${project.cuid}`,
    {
      params: {
        include_comments: includeComments,
        include_activity_feed: includeActivityFeed,
        include_status: includeStatus,
        include_findings: includeFindings,
        format,
        downloadFilename,
      },
      responseType: format === 'json' ? 'text' : 'blob',
      headers: AuthHeaders(accessToken),
    },
  );
};

const DownloadDocumentationReport = async (
  project: TProject,
  includeComments: boolean,
  includeActivityFeed: boolean,
  includeStatus: boolean,
  includeFindings: boolean,
  format: string,
  downloadFilename: string,
  accessToken: string,
): Promise<any> => {
  return await axiosInstance.get(
    `/reports/${project.inventory_model.cuid}/documentation-report/${project.cuid}`,
    {
      params: {
        include_comments: includeComments,
        include_activity_feed: includeActivityFeed,
        include_status: includeStatus,
        include_findings: includeFindings,
        format,
        downloadFilename,
      },
      responseType: 'blob',
      headers: AuthHeaders(accessToken),
    },
  );
};

const GetStatusesWorkflows = async (
  accessToken: string,
): Promise<TStatusesWorkflow[]> => {
  const response = await axiosInstance.get(`/statuses-workflows`, {
    headers: AuthHeaders(accessToken),
  });
  return response.data;
};

const GetStatusesWorkflow = async (
  accessToken: string,
  statusesWorkflowCuid: string,
): Promise<TStatusesWorkflow> => {
  const response = await axiosInstance.get(
    `/statuses-workflows/${statusesWorkflowCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const PostStatusesWorkflowStatus = async (
  accessToken: string,
  workflowCuid: string,
  workflowStatus: TStatusesWorkflowStatus,
): Promise<TStatusesWorkflowStatus> => {
  const response = await axiosInstance.post(
    `/statuses-workflows/${workflowCuid}/status`,
    { ...workflowStatus },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const PutStatusesWorkflowStatus = async (
  accessToken: string,
  workflowCuid: string,
  workflowStatus: TStatusesWorkflowStatus,
): Promise<TStatusesWorkflowStatus> => {
  const response = await axiosInstance.put(
    `/statuses-workflows/${workflowCuid}/status`,
    { ...workflowStatus },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const DeleteStatusesWorkflowStatus = async (
  accessToken: string,
  workflowCuid: string,
  workflowStatus: TStatusesWorkflowStatus,
): Promise<TStatusesWorkflowStatus> => {
  const response = await axiosInstance.delete(
    `/statuses-workflows/${workflowCuid}/status/${workflowStatus.cuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

/**
 * Checks if the there is a response body from the Axios response
 * and if the response boyd has a "message" field. Return this
 * or default to returning the error message
 */
const getAPIErrorMessage = (error: any): string => {
  const axiosError = error as AxiosError;
  if (
    axiosError.response &&
    axiosError.response.data &&
    axiosError.response.data.message
  ) {
    return axiosError.response.data.message;
  }
  return axiosError.message;
};

const GetOrganizationUseCaseCategories = async (
  accessToken: string,
  organization: TOrganization | null,
): Promise<PagedResponse<TUseCaseCategory>> => {
  const result = await axiosInstance.get(
    `/organization/${organization?.cuid}/use_cases/categories`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetOrganizationUseCases = async (
  accessToken: string,
  organization: TOrganization | null,
  filters?: TModelInventoryFilters,
  searchQuery?: string,
  page: number = 1,
  limit: number = 100,
  sortBy?: APIRequestSortBy,
): Promise<TUseCasePaginated> => {
  let parsedParams: any = { page, limit };

  if (sortBy) {
    parsedParams = {
      ...parsedParams,
      sort_by: sortBy.field,
      sort_order: sortBy.order,
    };
  }

  // prepare the filters by converting some objects to key,value pairs
  if (filters) {
    parsedParams = {
      ...parsedParams,
      ...filters,
    };
  }

  if (searchQuery) {
    parsedParams = {
      ...parsedParams,
      q: searchQuery,
    };
  }

  // Remove fields with null, undefined, or empty arrays
  parsedParams = Object.fromEntries(
    Object.entries(parsedParams).filter(([_, value]) => {
      return (
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    }),
  );

  let params = new URLSearchParams(parsedParams as keyof object).toString();

  const result = await axiosInstance.get(
    `/organization/${organization?.cuid}/use_cases?${params}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostOrganizationUseCase = async (
  accessToken: string,
  organization: TOrganization | null,
  useCase: TCreateUseCaseRequest,
): Promise<TUseCase> => {
  const result = await axiosInstance.post(
    `/organization/${organization?.cuid}/use_cases`,
    useCase,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteOrganizationUseCase = async (
  accessToken: string,
  organization: TOrganization | null,
  useCaseCuid: string,
): Promise<void> => {
  await axiosInstance.delete(
    `/organization/${organization?.cuid}/use_case/${useCaseCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
};

const GetModelInventory = async (
  accessToken: string,
  filters?: TModelInventoryFilters,
  searchQuery?: string,
  page: number = 1,
  limit: number = 100,
  sortBy?: APIRequestSortBy,
): Promise<TInventoryModelPaginated> => {
  // prepare the get params to be added to the API url
  let params = '';

  let parsedParams: any = { page, limit };

  if (sortBy) {
    parsedParams = {
      ...parsedParams,
      sort_by: sortBy.field,
      sort_order: sortBy.order,
    };
  }

  // prepare the filters by converting some objects to key,value pairs
  if (filters) {
    parsedParams = {
      ...parsedParams,
      ...filters,
      owners: filters?.owners?.map(u => u.cuid) || [],
      developers: filters?.developers?.map(u => u.cuid) || [],
      validators: filters?.validators?.map(u => u.cuid) || [],
      business_units: filters?.business_units?.map(bu => bu.cuid) || [],
      status: filters?.status?.map(s => s.cuid) || [],
      is_vendor_model: filters?.is_vendor_model || undefined,
    };
  }

  if (searchQuery) {
    parsedParams = {
      ...parsedParams,
      q: searchQuery,
    };
  }

  // Remove fields with null, undefined, or empty arrays
  parsedParams = Object.fromEntries(
    Object.entries(parsedParams).filter(([_, value]) => {
      return (
        value !== null &&
        value !== undefined &&
        !(Array.isArray(value) && value.length === 0)
      );
    }),
  );

  params = new URLSearchParams(parsedParams as keyof object).toString();

  const result = await axiosInstance.get(`/inventory-models?${params}`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetModelInventoryFilters = async (
  accessToken: string,
  customFields?: SchemaPropertyItem[],
): Promise<TModelInventoryFilters> => {
  let url = `/inventory-models/filters`;
  if (customFields) {
    const keys = customFields.map(column => column.key).join(',');
    url = `${url}?custom_fields=${keys}`;
  }
  const result = await axiosInstance.get(url, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetProjectFilters = async (
  accessToken: string,
): Promise<TProjectFilters> => {
  const result = await axiosInstance.get(`/projects/filters`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetInventoryModel = async (
  accessToken: string,
  cuid: string,
): Promise<TInventoryModel> => {
  const result = await axiosInstance.get(`/inventory-models/${cuid}`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetInventoryModelProjects = async (
  accessToken: string,
  cuid: string,
): Promise<TProject[]> => {
  const result = await axiosInstance.get(`/inventory-models/${cuid}/projects`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const CreateInventoryModel = async (
  accessToken: string,
  inventoryModel: TInventoryModelPost,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(
    `/inventory-models`,
    { ...inventoryModel },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const PostInventoryModelUser = async (
  accessToken: string,
  inventoryModel: TInventoryModel,
  inventoryModelUser: TInventoryModelUserPost,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/users`,
    { ...inventoryModelUser },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const DeleteInventoryModelUser = async (
  accessToken: string,
  inventoryModel: TInventoryModel,
  inventoryModelUser: TInventoryModelUser,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.delete(
    `/inventory-models/${inventoryModel.cuid}/users/${inventoryModelUser.cuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

/**
 * Retrieve a JSON image from a pre-signed URL
 */
const GetJSONImage = async (url: string): Promise<string> => {
  const result = await axiosInstance.get(url);
  return result.data;
};

const PostAnnotation = async (
  project: TProject,
  target: TProjectMetadata,
  thread: CommentsAdapterThreadAdded,
  accessToken: string,
): Promise<TProjectAnnotationCKEditor> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/annotations`,
    { thread, target },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetAnnotation = async (
  accessToken: string,
  project: TProject,
  annotationId: string,
): Promise<TProjectAnnotationCKEditor> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/annotations/${annotationId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostComment = async (
  accessToken: string,
  project: TProject,
  threadId: string,
  content: string,
  commentId?: string,
): Promise<TProjectAnnotationCommentCKEditor> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/annotations/${threadId}/comments`,
    {
      commentId,
      content,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchComment = async (
  accessToken: string,
  project: TProject,
  threadId: string,
  commentId: string,
  content: string,
): Promise<TProjectAnnotationCommentCKEditor> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/annotations/${threadId}/comments/${commentId}`,
    {
      content,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteComment = async (
  accessToken: string,
  project: TProject,
  threadId: string,
  commentId: string,
): Promise<TProjectAnnotationComment> => {
  const result = await axiosInstance.delete(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/annotations/${threadId}/comments/${commentId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteAnnotation = async (
  accessToken: string,
  project: TProject,
  threadId: string,
): Promise<TProjectAnnotationComment> => {
  const result = await axiosInstance.delete(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/annotations/${threadId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const ResolveAnnotation = async (
  accessToken: string,
  project: TProject,
  threadId: string,
  resolved: boolean,
): Promise<TProjectAnnotation> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/annotations/${threadId}`,
    {
      resolved,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostSuggestion = async (
  project: TProject,
  target: TProjectMetadata,
  suggestion: TrackChangesAdapterSuggestionAdded,
  accessToken: string,
): Promise<TSuggestion> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/suggestions`,
    { suggestion, target },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetSuggestion = async (
  accessToken: string,
  project: TProject,
  suggestionId: string,
): Promise<TSuggestion> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/suggestions/${suggestionId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchSuggestion = async (
  accessToken: string,
  project: TProject,
  suggestionId: string,
  patchData: TrackChangesAdapterSuggestionUpdated,
): Promise<TSuggestion> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/suggestions/${suggestionId}`,
    patchData,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetRevisions = async (
  accessToken: string,
  project: TProject,
  metadataId: string,
): Promise<any[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/revisions/${metadataId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchRevisions = async (
  accessToken: string,
  project: TProject,
  metadataId: string,
  revisionsData: any,
  documentType?: string,
): Promise<any> => {
  let url = `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/revisions/${metadataId}`;
  if (documentType) {
    url = `${url}/for-comments-on/${documentType}`;
  }
  const result = await axiosInstance.patch(url, revisionsData, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const AddBlockToSection = async (
  accessToken: string,
  project: TProject,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  templateSection: TemplateSection,
  index: number,
  content: TemplateSectionContents,
): Promise<any> => {
  const response = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/templates/${documentType}/latest/add-block`,
    { templateSection, index, content },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const RemoveBlockFromSection = async (
  accessToken: string,
  project: TProject,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  templateSection: TemplateSection,
  content: TemplateSectionContents,
): Promise<any> => {
  const response = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/templates/${documentType}/latest/remove-block`,
    { templateSection, content },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetProjectMetricKeys = async (
  accessToken: string,
  project: TProject,
  published_only: boolean = false,
  content_type?: ModelDocumentTypeEnum,
): Promise<TMetricResultKey[]> => {
  let params: any = {
    published_only,
  };

  if (content_type) {
    params = {
      ...params,
      content_type,
    };
  }

  const response = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/metrics/keys`,
    {
      params,
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetProjectTestResultsKeys = async (
  accessToken: string,
  project: TProject,
  published_only: boolean = false,
  content_type?: ModelDocumentTypeEnum,
): Promise<TTestResultKey[]> => {
  let params: any = {
    published_only,
  };

  if (content_type) {
    params = {
      ...params,
      content_type,
    };
  }

  const response = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/test-results/keys`,
    {
      params,
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const AddUsersToGroup = async (
  accessToken: string,
  groupCuid: string,
  userCuids: string[],
): Promise<TGroupMembership[]> => {
  const response = await axiosInstance.post(
    `/groups/${groupCuid}/users`,
    {
      users: userCuids,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const RemoveUsersFromGroup = async (
  accessToken: string,
  groupCuid: string,
  userCuids: string[],
): Promise<{ removed: string[] }> => {
  const response = await axiosInstance.delete(`/groups/${groupCuid}/users`, {
    headers: AuthHeaders(accessToken),
    data: {
      users: userCuids,
    },
  });
  return response.data;
};

const CreateGroup = async (
  accessToken: string,
  name: string,
  description: string,
): Promise<TUserRole> => {
  const result = await axiosInstance.post(
    '/groups',
    {
      name,
      description,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetGroups = async (
  accessToken: string,
  page?: number,
): Promise<APIPaginatedResponse<TGroup>> => {
  const params = page ? { page } : {};
  const response = await axiosInstance.get('/groups', {
    headers: AuthHeaders(accessToken),
    params,
  });
  return response.data;
};

const DeleteGroup = async (
  accessToken: string,
  groupId: string,
): Promise<void> => {
  await axiosInstance.delete(`/groups/${groupId}`, {
    headers: AuthHeaders(accessToken),
  });
};

const GetGroupUsers = async (
  accessToken: string,
  groupId: string,
): Promise<TUser[]> => {
  const response = await axiosInstance.get(`/groups/${groupId}/users`, {
    headers: AuthHeaders(accessToken),
  });
  return response.data;
};

const GetGroupTemplates = async (
  accessToken: string,
  groupId: string,
): Promise<Template[]> => {
  const response = await axiosInstance.get(
    `/groups/${groupId}/resources?type=Template`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetGroupWorkflows = async (
  accessToken: string,
  groupId: string,
): Promise<Template[]> => {
  const response = await axiosInstance.get(
    `/groups/${groupId}/resources?type=StatusesWorkflow`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetGroupInventoryModels = async (
  accessToken: string,
  groupId: string,
): Promise<TInventoryModel[]> => {
  const response = await axiosInstance.get(
    `/groups/${groupId}/resources?type=InventoryModel`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const CreateOrganization = async (
  accessToken: string,
  organizationName: string,
  name: string,
  lastName: string,
  jobTitle: string,
  hasOptedInForNewsletter: boolean,
  hasOptedInForSlack: boolean,
  hasAcceptedTermsAndConditions: boolean,
): Promise<TOrganization> => {
  const response = await axiosInstance.post(
    `/organizations`,
    {
      name,
      organization_name: organizationName,
      last_name: lastName,
      job_title: jobTitle,
      opted_in_for_newsletter: hasOptedInForNewsletter,
      opted_in_for_slack: hasOptedInForSlack,
      accepted_tyc: hasAcceptedTermsAndConditions,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetValidationGuidelineSummary = async (
  accessToken: string,
  project: TProject,
  contentIds: string[],
): Promise<AssessmentOptionWithSummary[]> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${
      project.cuid
    }/guidelines/assessments/summary?content_ids=${contentIds.join(',')}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};
const GetValidationGuideline = async (
  accessToken: string,
  project: TProject,
  contentId: string,
): Promise<Guideline> => {
  const result = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/guidelines/${contentId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostValidationGuidelineAssessment = async (
  accessToken: string,
  project: TProject,
  contentId: string,
  assessmentOptionCuid: string,
): Promise<Assessment> => {
  const result = await axiosInstance.post(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/guidelines/${contentId}/assessments`,
    {
      assessment_option_cuid: assessmentOptionCuid,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchValidationGuidelineAssessment = async (
  accessToken: string,
  project: TProject,
  contentId: string,
  assessment: Assessment,
): Promise<Assessment> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/guidelines/${contentId}/assessments`,
    {
      notes: assessment.notes,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchValidationGuidelineAssessmentEvidences = async (
  accessToken: string,
  project: TProject,
  contentId: string,
  testContentIds: string[],
  metricContentIds: string[],
  selectedDocumentType: ModelDocumentTypeEnum,
): Promise<{
  added: Array<Pick<TTestResult, 'cuid'>>;
  deleted: Array<Pick<TTestResult, 'cuid'>>;
}> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/guidelines/${contentId}/assessments/evidences`,
    {
      test_results: testContentIds,
      metric_results: metricContentIds,
      content_type: selectedDocumentType,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchValidationGuidelineAssessmentFindings = async (
  accessToken: string,
  project: TProject,
  contentId: string,
  findingCuids: string[],
): Promise<{
  added: Array<Pick<TFinding, 'cuid'>>;
  deleted: Array<Pick<TFinding, 'cuid'>>;
}> => {
  const result = await axiosInstance.patch(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/guidelines/${contentId}/assessments/findings`,
    {
      findings: findingCuids,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetInvitationCheck = async (
  inviteCuid: string,
): Promise<{ result: boolean; user_exists: boolean }> => {
  const response = await axiosInstance.get(
    `/user-invites/check?invite=${inviteCuid}`,
  );
  return response.data;
};

const PostInvitationAccept = async (
  accessToken: string,
  inviteCuid: string | null,
): Promise<any> => {
  const response = await axiosInstance.post(
    `/user-invites/accept?invite=${inviteCuid}`,
    {},
    { headers: AuthHeaders(accessToken) },
  );
  return response.data;
};

const GetUserInvitations = async (
  accessToken: string,
  page: number = 1,
  limit: number = 100,
  status?: 'pending' | 'accepted',
): Promise<APIPaginatedResponse<TUserInvite>> => {
  const params = { page, limit, status };
  const result = await axiosInstance.get(`/user-invites`, {
    params,
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PostUserInvitation = async (
  accessToken: string,
  email: string,
  groups: Array<TGroup['cuid']>,
  roles: Array<TRole['cuid']>,
): Promise<{ result: boolean }> => {
  const response = await axiosInstance.post(
    `/user-invites`,
    { email, groups, roles },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const ListUserRoles = async (
  accessToken: string,
): Promise<TUserRolePaginated> => {
  const response = await axiosInstance.get(`/user-roles`, {
    headers: AuthHeaders(accessToken),
  });
  return response.data;
};

const CreateUserRole = async (
  accessToken: string,
  user_id: string,
  role_id: string,
): Promise<TUserRole> => {
  const result = await axiosInstance.post(
    `/user-roles`,
    {
      user_id,
      role_id,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteUserRole = async (
  accessToken: string,
  userRoleId: string,
): Promise<TUserRole> => {
  const result = await axiosInstance.delete(`/user-roles/${userRoleId}`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetInventoryModelSchema = async (
  accessToken: string,
): Promise<TInventoryModelSchema> => {
  const result = await axiosInstance.get(`/inventory-models/schema`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PutInventoryModelSchema = async (
  accessToken: string,
  schema: RJSFSchema,
  settings: RJSFSchemaSettings,
  operation: 'add' | 'remove' | 'update',
  properties: {
    schema: JSONSchema7;
    settings: RJSFSchemaSettingsProperty;
  },
): Promise<TInventoryModelSchema> => {
  const result = await axiosInstance.put(
    `/inventory-models/schema`,
    {
      schema,
      settings,
      operation,
      properties,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetModelInventoryCustomField = async (
  accessToken: string,
  inventoryModel: TInventoryModel,
  key: string,
): Promise<TInventoryModelCustomField> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModel.cuid}/fields/${key}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostModelInventoryCustomField = async (
  accessToken: string,
  inventoryModel: TInventoryModel,
  key: string,
  formData: any,
): Promise<TInventoryModelCustomField> => {
  const result = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/fields/${key}`,
    formData,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetModelsByTemplateVersion = async (
  accessToken: string,
  documentType: string,
  template: Template,
  templateVersion: TemplateVersion,
): Promise<TInventoryModel[]> => {
  const response = await axiosInstance.get(
    `/templates/${template.cuid}/version/${templateVersion.cuid}/models?document_type=${documentType}`,
    { headers: AuthHeaders(accessToken) },
  );
  return response.data;
};

const DuplicateTemplate = async (
  accessToken: string,
  template: Template,
  templateVersion: TemplateVersion,
  description: string,
  new_name: string,
): Promise<Template> => {
  const response = await axiosInstance.post(
    `/templates/${template.cuid}/version/${templateVersion.cuid}/duplicate`,
    { description, new_name },
    { headers: AuthHeaders(accessToken) },
  );
  return response.data;
};

const GetRiskAreas = async (
  accessToken: string,
): Promise<PagedResponse<TRiskArea>> => {
  const result = await axiosInstance.get(`/risk-areas`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PostRiskArea = async (
  accessToken: string,
  riskArea: TAddRiskAreaRequest,
): Promise<TRiskArea> => {
  const result = await axiosInstance.post(`/risk-areas`, riskArea, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PatchRiskArea = async (
  accessToken: string,
  riskAreaCuid: string,
  riskArea: TAddRiskAreaRequest,
): Promise<TRiskArea> => {
  const result = await axiosInstance.patch(
    `/risk-areas/${riskAreaCuid}`,
    riskArea,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteRiskArea = async (
  accessToken: string,
  riskAreaCuid: string,
): Promise<TRiskArea> => {
  const result = await axiosInstance.delete(`/risk-areas/${riskAreaCuid}`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetRiskAreaDependencies = async (
  accessToken: string,
  riskAreaCuid: string,
): Promise<TRiskAreaDependencies> => {
  const result = await axiosInstance.get(
    `/risk-areas/${riskAreaCuid}/check-dependencies`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetAnalytics = async (
  accessToken: string,
  table: string, // one of 'findings' or 'models'
  groupBy: string[],
  filterKeyCols: string[],
): Promise<TReport> => {
  const result = await axiosInstance.get(`/analytics/${table}`, {
    headers: AuthHeaders(accessToken),
    params: {
      group_by: groupBy.join(','),
      filter_key_columns: filterKeyCols.join(','),
    },
  });
  return result.data;
};

const GetAnalyticsBUByStatusDuration = async (
  accessToken: string,
): Promise<
  {
    status_name: string;
    [key: string]: any;
  }[]
> => {
  const result = await axiosInstance.get(
    '/analytics/models/business-units-by-status-duration',
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetAnalyticsFindingsCount = async (
  accessToken: string,
  where: {
    column: string;
    operator:
      | '='
      | '!='
      | '<'
      | '>'
      | '<='
      | '>='
      | 'is'
      | 'is not'
      | 'like'
      | 'ilike';
    value: '$null' | string | number | boolean;
  }[],
): Promise<number> => {
  const result = await axiosInstance.get(`/analytics/findings/count`, {
    headers: AuthHeaders(accessToken),
    params: {
      wherejson: JSON.stringify(where),
    },
  });
  return result.data;
};

const GetAnalyticsModelsCount = async (
  accessToken: string,
): Promise<number> => {
  const result = await axiosInstance.get(`/analytics/models/count`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetWorkflows = async (
  accessToken: string,
): Promise<WorkflowPaginated> => {
  const result = await axios.get(`${CONFIG.REACT_APP_API_URL}/workflows`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetWorkflow = async (
  accessToken: string,
  workflowCuid: string,
  version: string,
): Promise<Workflow> => {
  const result = await axios.get(
    `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}?version=${version}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostWorkflowVersionSource = async (
  accessToken: string,
  workflowCuid: string,
  version: string,
  source: string,
): Promise<Workflow> => {
  const result = await axios.post(
    `${CONFIG.REACT_APP_API_URL}/workflows/${workflowCuid}/${version}`,
    { source },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetStatusesWorkflowStatus = async (
  accessToken: string,
  statusCuid: string,
): Promise<TStatusesWorkflowStatus> => {
  const result = await axios.get(
    `${CONFIG.REACT_APP_API_URL}/statuses/${statusCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetWorkflowStatusForms = async (
  accessToken: string,
  triggerId: string,
  entityCuid: string,
): Promise<WorkflowStatusForm[]> => {
  const result = await axios.get(
    `${CONFIG.REACT_APP_API_URL}/workflows/status_form/${triggerId}/${entityCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const TriggerWorkflowStatusForm = async (
  accessToken: string,
  triggerId: string,
  entityCuid: string,
  trigger: string,
  fieldsValues: FieldValues,
  notes: string,
): Promise<WorkflowStatusForm[]> => {
  const result = await axios.post(
    `${CONFIG.REACT_APP_API_URL}/workflows/status_form/${triggerId}/${entityCuid}`,
    {
      trigger: trigger,
      field_values: fieldsValues,
      notes,
    },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetApprovalVoters = async (
  accessToken: string,
  voterStatus?: string | null,
  approvalStatus?: string,
  targetType?: string,
  targetCuid?: string,
): Promise<ApprovalVoter[]> => {
  const result = await axios.get(
    `${CONFIG.REACT_APP_API_URL}/approvals/voters`,
    {
      headers: AuthHeaders(accessToken),
      params: {
        voter_status: voterStatus,
        approval_status: approvalStatus,
        target_type: targetType,
        target_cuid: targetCuid,
      },
    },
  );
  return result.data;
};

const PostApprovalVote = async (
  accessToken: string,
  voterCuid: string,
  vote: ApprovalVote,
  notes: string,
): Promise<ApprovalVoter> => {
  const result = await axios.post(
    `${CONFIG.REACT_APP_API_URL}/approvals/voters/${voterCuid}`,
    { vote, notes },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetWorkflowExecutions = async (
  accessToken: string,
  triggerId: string,
  targetCuid: string,
): Promise<ExecutionResponse> => {
  const result = await axios.get(
    `${CONFIG.REACT_APP_API_URL}/workflows/executions/${triggerId}/${targetCuid}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const StartWorkflowExecutions = async (
  accessToken: string,
  triggerId: string,
  targetCuid: string,
): Promise<ExecutionResponse> => {
  const result = await axios.post(
    `${CONFIG.REACT_APP_API_URL}/workflows/executions/${triggerId}/${targetCuid}`,
    {},
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetDocumentationSummary = async (
  accessToken: string,
  inventoryModelCUID: string,
  contentType: string = 'model_documentation',
): Promise<TInventoryModelDocumentationSummary> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/documentation-overview/${contentType}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const GetValidationReportSummary = async (
  accessToken: string,
  inventoryModelCUID: string,
): Promise<TInventoryModelValidationReportSummary> => {
  const result = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/validation-report-overview`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PatchDocumentSectionStatus = async (
  accessToken: string,
  inventoryModelCUID: string,
  documentType: 'model_documentation' | 'validation_report',
  sectionId: string,
  status: 'in_progress' | 'done',
): Promise<any> => {
  const response = await axiosInstance.patch(
    `/inventory-models/${inventoryModelCUID}/document-section-status/${documentType}/${sectionId}`,
    { status },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const PatchModelInventoryGroup = async (
  accessToken: string,
  inventoryModelCUID: string,
  groupCUID: string,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.patch(
    `/inventory-models/${inventoryModelCUID}/set-group`,
    { group_cuid: groupCUID },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetGuidelines = async (
  accessToken: string,
  riskAreaCuid?: string,
  paging?: {
    limit: number;
  },
): Promise<PagedResponse<Guideline>> => {
  const limit = paging?.limit || 100;

  const params = {
    risk_area_cuid: riskAreaCuid,
    limit: limit,
  };

  const result = await axiosInstance.get(`/guidelines`, {
    params,
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PostGuideline = async (
  accessToken: string,
  guideline: GuidelineRequest,
): Promise<Guideline> => {
  const result = await axiosInstance.post('/guidelines', guideline, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PatchGuideline = async (
  accessToken: string,
  guidelineCuid: string,
  guideline: Omit<GuidelineRequest, 'content_id'>,
): Promise<Guideline> => {
  const result = await axiosInstance.patch(
    `/guidelines/${guidelineCuid}`,
    guideline,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DeleteGuideline = async (
  accessToken: string,
  guidelineCuid: string,
): Promise<Guideline> => {
  const result = await axiosInstance.delete(`/guidelines/${guidelineCuid}`, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetGuidelineDependencies = async (
  accessToken: string,
  guidelineCuid: string,
): Promise<GuidelineDependencies> => {
  const result = await axiosInstance.get(
    `/guidelines/${guidelineCuid}/check-dependencies`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const PostRole = async (
  accessToken: string,
  role: TRoleRequest,
): Promise<TRole> => {
  const result = await axiosInstance.post('/roles', role, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const PatchOrganizationRole = async (
  accessToken: string,
  role: RolePatchRequest,
): Promise<TRole> => {
  const result = await axiosInstance.patch(`/roles/${role.cuid}`, role, {
    headers: AuthHeaders(accessToken),
  });
  return result.data;
};

const GetRolePermissions = async (
  accessToken: string,
  roleCUID: string,
): Promise<TRolePermissions> => {
  const response = await axiosInstance.get(`/roles/${roleCUID}/permissions`, {
    headers: AuthHeaders(accessToken),
  });

  let role = response.data.role;
  let categories = response.data.categories;

  Object.keys(categories).forEach(category => {
    categories[category] = categories[category].filter(
      (a: TAssignedPermission) => !a.scope || a.scope === (role.scope || ''),
    );
  });

  return response.data;
};

const PatchRolePermissions = async (
  accessToken: string,
  roleCUID: string,
  rolePatch: {
    added_permissions?: string[];
    deleted_permissions?: string[];
  },
): Promise<Boolean> => {
  const response = await axiosInstance.patch(
    `/roles/${roleCUID}/permissions`,
    rolePatch,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetPermissionsOverview = async (
  accessToken: string,
): Promise<TPermissionsOverview> => {
  const response = await axiosInstance.get(`/roles/permissions-overview`, {
    headers: AuthHeaders(accessToken),
  });

  return response.data;
};

const GetUserPermissions = async (
  accessToken: string,
  scope?: string,
  cuid?: string,
): Promise<TPermissionAction[]> => {
  const response = await axiosInstance.get(
    `/users/me/permissions?${scope ? `scope=${scope}&cuid=${cuid}` : ''}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetTests = async (
  accessToken: string,
  project: TProject,
  content_type?: ModelDocumentTypeEnum,
): Promise<TTest[]> => {
  const response = await axiosInstance.get(
    `/inventory-models/${project.inventory_model.cuid}/projects/${project.cuid}/tests?content_type=${content_type}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const UploadOfflineDocument = async (
  accessToken: string,
  project: TProject,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
  attachments: File[],
): Promise<TInventoryModel> => {
  const formData = new FormData();
  for (let i = 0; i < attachments.length; i++) {
    formData.append(`attachments[]`, attachments[i]);
  }

  const result = await axios.post(
    `${CONFIG.REACT_APP_API_URL}/inventory-models/${project.inventory_model.cuid}/documents/${documentType}/upload`,
    formData,
    {
      headers: {
        ...AuthHeaders(accessToken),
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const DeleteOfflineDocument = async (
  accessToken: string,
  project: TProject,
  documentType: 'model_documentation' | 'validation_report' | 'monitoring',
): Promise<TInventoryModel> => {
  const result = await axios.delete(
    `${CONFIG.REACT_APP_API_URL}/inventory-models/${project.inventory_model.cuid}/documents/${documentType}/upload`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return result.data;
};

const DownloadOfflineDocument = async (
  accessToken: string,
  file: OfflineDocumentationFile,
): Promise<Blob> => {
  const response = await axiosInstance.get(file.url, {
    responseType: 'blob',
    headers: AuthHeaders(accessToken),
  });

  return response.data;
};

export const ResetWorkflow = async (
  accessToken: string,
  triggerId: string,
  targetCuid: string,
): Promise<void> => {
  await axiosInstance.patch(
    `/workflows/executions/${triggerId}/${targetCuid}/reset`,
    {},
    {
      headers: AuthHeaders(accessToken),
    },
  );
};

const PostInventoryModelDependencies = async (
  accessToken: string,
  inventoryModel: TInventoryModel,
  dependencies: TInventoryModelDependency[],
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/dependencies`,
    { dependencies },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const PostCodeTest = async (
  accessToken: string,
  code: string,
  variables: any,
  execute: boolean,
): Promise<CodeTestResponse> => {
  const response = await axiosInstance.post(
    `/custom-fields/code/test`,
    { code, variables, execute },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const PostInventoryModelStage = async (
  accessToken: string,
  inventoryModel: TInventoryModel,
  stage: InventoryModelStage,
): Promise<TInventoryModel> => {
  const response = await axiosInstance.post(
    `/inventory-models/${inventoryModel.cuid}/stages`,
    { stage },
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const GetEntityAttachments = async (
  accessToken: string,
  entity_type: EntityType,
  entity_cuid: string,
  entity_field_id: string,
): Promise<EntityAttachment> => {
  const response = await axiosInstance.get(
    `/entity-attachments/${entity_type}/${entity_cuid}/${entity_field_id}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

const UploadEntityAttachmentsFiles = async (
  accessToken: string,
  entity_type: EntityType,
  entity_cuid: string,
  entity_field_id: string,
  attachments: File[],
): Promise<EntityAttachment> => {
  const formData = new FormData();
  for (let i = 0; i < attachments.length; i++) {
    formData.append(`files[]`, attachments[i]);
  }

  const result = await axios.post(
    `${CONFIG.REACT_APP_API_URL}/entity-attachments/${entity_type}/${entity_cuid}/${entity_field_id}/upload_files`,
    formData,
    {
      headers: {
        ...AuthHeaders(accessToken),
        'Content-Type': 'multipart/form-data',
      },
    },
  );
  return result.data;
};

const DeleteEntityAttachmentsFiles = async (
  accessToken: string,
  entity_type: EntityType,
  entity_cuid: string,
  entity_field_id: string,
  file_ids: string[],
): Promise<EntityAttachment> => {
  const result = await axios.delete(
    `${CONFIG.REACT_APP_API_URL}/entity-attachments/${entity_type}/${entity_cuid}/${entity_field_id}/delete_files`,
    {
      headers: AuthHeaders(accessToken),
      params: {
        file_ids: file_ids,
      },
    },
  );
  return result.data;
};

export const GetUnitMetrics = async (
  accessToken: string,
  inventoryModelCUID: string,
): Promise<TUnitMetric[]> => {
  const response = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/unit_metrics`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

export const GetUnitMetricsValuesForKey = async (
  accessToken: string,
  inventoryModelCUID: string,
  key: string,
  start?: string,
  end?: string,
  limit: number = 999999,
): Promise<PagedResponse<TUnitMetric>> => {
  const response = await axiosInstance.get(
    `/inventory-models/${inventoryModelCUID}/unit_metrics/${key}`,
    {
      headers: AuthHeaders(accessToken),
      params: {
        start,
        end,
        limit,
      },
    },
  );
  return response.data;
};

export const GetModelDocument = async (
  accessToken: string,
  modelDocumentId: string,
): Promise<ModelDocument> => {
  /* This is the best way to get a model document
   * Presents the opportunity to include document cuids to
   * the rbac policies and enforce on a more granular way
   * */
  const response = await axiosInstance.get(
    `model-documents/${modelDocumentId}`,
    {
      headers: AuthHeaders(accessToken),
    },
  );
  return response.data;
};

export const ResendVerificationEmail = async (
  auth0UserId: string,
): Promise<{ verification_sent: Boolean }> => {
  const response = await axiosInstance.post(
    `/resend-verification-email/${auth0UserId}`,
  );
  return response.data;
};

const actions = {
  getAPIErrorMessage,
  CreateProject,
  CreateProjectChecks,
  GetCurrentUser,
  GetOrganization,
  GetOrganizationConnection,
  GetOrganizationUsers,
  GetOrganizationRoles,
  GetOrganizationUseCases,
  GetOrganizationUseCaseCategories,
  PostOrganizationUseCase,
  DeleteOrganizationUseCase,
  GetProjects,
  GetProjectByModelId,
  GetProjectSearch,
  GetProjectMetadataByContentId,
  PostProjectMetadata,
  PostProjectMetadataAttachment,
  GetEvents,
  GetMetricForProject,
  GetMetricHistoryForProject,
  GetMetricsForProject,
  GetCommentsForProject,
  GetTestResults,
  GetTestResultForProject,
  GetTestResultHistoryForProject,
  PostCommentForProject,
  UpdateOrganization,
  GetProjectFigures,
  GetProjectFigureByKey,
  GetTemplates,
  GetTemplateVersion,
  UpdateTemplate,
  GetFindings,
  GetFindingFilters,
  GetFinding,
  PostFinding,
  UpdateFinding,
  DeleteFinding,
  PostAnnotation,
  GetAnnotation,
  DeleteAnnotation,
  ResolveAnnotation,
  PostComment,
  PatchComment,
  DeleteComment,
  PostSuggestion,
  GetSuggestion,
  PatchSuggestion,
  GetRevisions,
  PatchRevisions,
  PostFindingAttachment,
  GetStatusesWorkflows,
  GetStatusesWorkflow,
  PostStatusesWorkflowStatus,
  PutStatusesWorkflowStatus,
  DeleteStatusesWorkflowStatus,
  GetModelInventory,
  GetModelInventoryFilters,
  GetInventoryModel,
  GetInventoryModelProjects,
  GetFindingSeverities,
  GetAnnotations,
  GetProjectFilters,
  DownloadDocumentationReport,
  DownloadValidationReport,
  GetOrganizationBusinessUnits,
  PostOrganizationBusinessUnits,
  DeleteOrganizationBusinessUnit,
  PatchOrganizationBusinessUnit,
  CreateInventoryModel,
  PostInventoryModelUser,
  DeleteInventoryModelUser,
  GetJSONImage,
  SwapTemplate,
  SetTemplate,
  AddBlockToSection,
  RemoveBlockFromSection,
  GetProjectMetricKeys,
  GetProjectTestResultsKeys,
  AddUsersToGroup,
  RemoveUsersFromGroup,
  CreateGroup,
  GetGroups,
  DeleteGroup,
  GetGroupUsers,
  GetGroupTemplates,
  GetGroupWorkflows,
  GetGroupInventoryModels,
  CreateOrganization,
  PatchCurrentUserUISettings,
  PatchUserOrganization,
  GetValidationGuideline,
  PostValidationGuidelineAssessment,
  PatchValidationGuidelineAssessment,
  PatchValidationGuidelineAssessmentEvidences,
  PatchValidationGuidelineAssessmentFindings,
  GetOrganizationAssessmentOptions,
  GetValidationGuidelineSummary,
  GetInvitationCheck,
  PostInvitationAccept,
  GetUserInvitations,
  PostUserInvitation,
  ListUserRoles,
  CreateUserRole,
  DeleteUserRole,
  GetInventoryModelSchema,
  PutInventoryModelSchema,
  GetModelInventoryCustomField,
  PostModelInventoryCustomField,
  ValidateTemplate,
  DuplicateTemplate,
  GetModelsByTemplateVersion,
  GetRiskAreas,
  PostRiskArea,
  PatchRiskArea,
  DeleteRiskArea,
  GetRiskAreaDependencies,
  GetAnalytics,
  GetAnalyticsBUByStatusDuration,
  GetAnalyticsFindingsCount,
  GetAnalyticsModelsCount,
  GetDocumentationSummary,
  GetWorkflows,
  GetWorkflow,
  PostWorkflowVersionSource,
  GetStatusesWorkflowStatus,
  GetWorkflowStatusForms,
  TriggerWorkflowStatusForm,
  GetApprovalVoters,
  PostApprovalVote,
  GetWorkflowExecutions,
  StartWorkflowExecutions,
  PatchDocumentSectionStatus,
  GetValidationReportSummary,
  PatchModelInventoryGroup,
  GetGuidelines,
  PostGuideline,
  PatchGuideline,
  DeleteGuideline,
  GetGuidelineDependencies,
  PostRole,
  GetRolePermissions,
  PatchRolePermissions,
  GetPermissionsOverview,
  GetUserPermissions,
  GetDocument,
  GetFeatureFlags,
  GetTests,
  UploadOfflineDocument,
  DeleteOfflineDocument,
  DownloadOfflineDocument,
  PostInventoryModelDependencies,
  PostCodeTest,
  ResetWorkflow,
  PatchOrganizationRole,
  PostInventoryModelStage,
  GetEntityAttachments,
  UploadEntityAttachmentsFiles,
  DeleteEntityAttachmentsFiles,
  GetUnitMetrics,
  GetUnitMetricsValuesForKey,
  GetModelDocument,
  ResendVerificationEmail,
};

export default actions;
