import { useContext } from 'react';
import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { JSONSchema7 } from 'json-schema';
import UsersContext from '../../../../../contexts/UsersContext';
import { FieldEditProps } from '../../index';
import { UiSchema } from '@rjsf/utils';
import { HStack, Button } from '@chakra-ui/react';

const UserFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const { organizationUsers } = useContext(UsersContext);

  const oneOfEntities = organizationUsers.map(user => ({
    const: user.cuid,
    title: user.name,
  }));

  const isMany = field.settings.properties[field.key].many;

  const schema: JSONSchema7 = {
    type: 'object',
    properties: {
      entityValues: isMany
        ? {
            type: 'array',
            items: { type: 'string', oneOf: oneOfEntities },
            uniqueItems: true,
          }
        : { type: 'string', oneOf: oneOfEntities },
    },
  };

  const onSubmit = (e: any) => {
    const formData = {
      [field.key]: isMany ? e.formData.entityValues : [e.formData.entityValues],
    };
    onEdit(formData);
  };

  const baseUiSchema: UiSchema = {
    'ui:globalOptions': { label: false },
  };

  const uiSchema = isMany
    ? { ...baseUiSchema, entityValues: { 'ui:widget': 'checkboxes' } }
    : baseUiSchema;
  const handleCancel = () => {
    onCancel({});
  };
  return (
    <Form
      schema={schema}
      formData={{ entityValues: field.value }}
      validator={validator}
      onSubmit={onSubmit}
      uiSchema={uiSchema}
      onChange={e => {
        onChange?.({
          [field.key]: isMany
            ? e.formData.entityValues
            : [e.formData.entityValues],
        });
      }}
    >
      {!onChange ? (
        <HStack justifyContent={'flex-end'}>
          <Button
            onClick={() => {
              handleCancel();
            }}
            variant={'ghost'}
          >
            Cancel
          </Button>
          <Button type="submit" variant={'primary'}>
            Save
          </Button>
        </HStack>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default UserFieldEdit;
