import validator from 'validator';

type ValidationObject = {
  value: any;
};

type CustomFieldValidatorProps = {
  fieldName: string;
};

export type ValidatorKey =
  | 'any:required'
  | 'text:url'
  | 'text:email'
  | 'date:is-unix-string'
  | 'number';

export type ValidationFunction = (
  validationObject: ValidationObject,
) => string | undefined;

type CustomFieldValidatorType = {
  [key in ValidatorKey]: ValidationFunction;
};

export const isMissing = (value: any) => {
  return (
    value === undefined ||
    value === null ||
    value === '' ||
    (Array.isArray(value) && value.length === 0)
  );
};

const CustomFieldValidator = ({
  fieldName,
}: CustomFieldValidatorProps): CustomFieldValidatorType => {
  return {
    'any:required': ({ value }) => {
      if (isMissing(value)) {
        return `"${fieldName}" cannot be empty`;
      }
    },
    'text:url': ({ value }) => {
      if (value && !validator.isURL(value)) {
        return `"${fieldName}" is not a valid url`;
      }
    },
    'text:email': ({ value }) => {
      if (value && !validator.isEmail(value)) {
        return `"${fieldName}" is not a valid email`;
      }
    },
    'date:is-unix-string': ({ value }) => {
      const number = parseInt(value);
      if (isNaN(value) || number < 0) {
        return `"${fieldName}" is not a valid date`;
      }
    },
    number: ({ value }) => {
      if (!value || isNaN(value)) {
        return `"${fieldName}" is not a valid number`;
      }
    },
  };
};

export default CustomFieldValidator;
