import { defineStyleConfig } from '@chakra-ui/react';

export const Link = defineStyleConfig({
  baseStyle: {
    color: 'secondaryBrand.600 !important',
    fontWeight: 'bold',
    _dark: {
      color: 'secondaryBrand.400 !important',
    },
    _focus: {
      outline: 'none',
      boxShadow: '0 0 0 3px var(--chakra-colors-brand-400)',
    },
  },
});
