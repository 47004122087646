/* TODO: Refactor this to use EditableField */
import { useEffect, useRef, useState } from 'react';
import {
  Box,
  HStack,
  Icon,
  Stack,
  Text,
  Tooltip,
  useColorModeValue,
  Button,
} from '@chakra-ui/react';
import { Label } from '../Layout';

import { ExclamationCircleIcon } from '@heroicons/react/20/solid';
import { PencilIcon } from '@heroicons/react/24/outline';
import CustomFieldRenderer from '../NewCustomFields/CustomFieldRenderer';
import useField from '../../hooks/useField';
import { CustomFieldJSONSchema } from '../NewCustomFields/types';
import { isMissing } from '../NewCustomFields/CustomFieldValidator';
import MoreInfoPopOver from '../MoreInfoPopOver';

export interface CustomFieldProps {
  jsonSchema: CustomFieldJSONSchema;
  initialValue: any;
  onEdit: (value: any, onSuccess: () => void) => void;
  overrideDisplay?: (mode: 'edit' | 'read-only') => JSX.Element | null;
  readOnly?: boolean;
  hideLabel?: boolean;
}

interface FieldTitleProps {
  title: string;
  description?: string;
  hideLabel?: boolean;
  isRequired?: boolean;
}
const FieldTitle = ({
  title,
  description,
  hideLabel,
  isRequired,
}: FieldTitleProps) => {
  if (hideLabel) {
    return <></>;
  }
  return (
    <HStack w={'full'} alignItems={'flex-start'}>
      <Label>{title}</Label>

      {isRequired && <Label color="red.500">*</Label>}
      {description && (
        <MoreInfoPopOver
          title={title}
          description={description}
          iconProps={{
            mt: '-2px',
            opacity: 0,
            _groupHover: { opacity: 1 },
          }}
        />
      )}
    </HStack>
  );
};
const ManagedField = ({
  jsonSchema,
  initialValue,
  onEdit,
  overrideDisplay,
  readOnly = false,
  hideLabel = false,
}: CustomFieldProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsEditingSaving] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing) {
      setIsActive(true);
    }
  }, [isEditing]);

  useEffect(() => {
    // Function to check if the clicked target is outside the Stack
    function handleClickOutside(event: any) {
      if (stackRef.current && !stackRef.current.contains(event.target)) {
        setIsActive(false); // Change the editing state
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
    };
  }, [stackRef]);

  const calculateBoxShadow = () => {
    if (isActive && isEditing)
      return useColorModeValue(
        `0px 0px 0px 7px var(--chakra-colors-secondaryBrand-50),
        0px 0px 0px 8px var(--chakra-colors-secondaryBrand-200),
        0px 0px 0px 9px var(--chakra-colors-brand-base),
        0px 0px 0px 10px var(--chakra-colors-secondaryBrand-50)`,
        `0px 0px 0px 7px var(--chakra-colors-neutral-800),
        0px 0px 0px 8px var(--chakra-colors-neutral-850),
        0px 0px 0px 9px var(--chakra-colors-brand-base),
        0px 0px 0px 10px var(--chakra-colors-brand-900)`,
      );
    if (!isActive && isEditing)
      return useColorModeValue(
        '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50)',
        '0px 0px 0px 8px var(--chakra-colors-neutral-800)',
      );
    return '0px 0px 0px 8px transparent, 0px 0px 0px 9px transparent';
  };
  const calculateHoverBoxShadow = () => {
    if (isEditing && isActive)
      return useColorModeValue(
        '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50), 0px 0px 0px 9px var(--chakra-colors-brand-base)',
        '0px 0px 0px 8px var(--chakra-colors-neutral-800), 0px 0px 0px 9px var(--chakra-colors-brand-base)',
      );
    if (isEditing && !isActive)
      return useColorModeValue(
        '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50), 0px 0px 0px 9px var(--chakra-colors-secondaryBrand-200)',
        '0px 0px 0px 8px var(--chakra-colors-neutral-800), 0px 0px 0px 9px var(--chakra-colors-neutral-700)',
      );
    return useColorModeValue(
      '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50), 0px 0px 0px 9px var(--chakra-colors-secondaryBrand-100)',
      '0px 0px 0px 8px var(--chakra-colors-neutral-800), 0px 0px 0px 9px var(--chakra-colors-neutral-800)',
    );
  };
  const onCancel = () => {
    setIsEditing(false);
  };

  const onEditWrapper = async (value: any) => {
    if (readOnly) {
      return;
    }

    setIsEditingSaving(true);
    onEdit(value, () => {
      setIsEditing(false);
      setIsEditingSaving(false);
    });
  };

  const mode = isEditing && !readOnly ? 'edit' : 'read-only';

  const displayComponent = overrideDisplay?.(mode);

  return (
    <Tooltip
      px={2}
      py={1}
      gutter={0}
      fontSize="md"
      openDelay={50}
      offset={[9, 4]}
      roundedTop={'md'}
      boxShadow={'none'}
      placement="top-end"
      roundedBottom={'none'}
      borderTop={'1px solid '}
      borderLeft={'1px solid '}
      borderRight={'1px solid '}
      isDisabled={isEditing || readOnly}
      label={<Icon as={PencilIcon} boxSize={4} />}
      bg={useColorModeValue('secondaryBrand.50', 'neutral.800')}
      color={useColorModeValue('neutral.800', 'neutral.200')}
      borderColor={useColorModeValue('secondaryBrand.100', 'neutral.850')}
    >
      <Stack
        ref={stackRef}
        w={'full'}
        gap={2}
        borderRadius={'2px'}
        cursor={isEditing || readOnly ? 'default' : 'pointer'}
        onClick={() => {
          setIsActive(true);
          if (!isEditing && !readOnly) {
            setIsEditing(true);
          }
        }}
        bg={
          isEditing
            ? useColorModeValue('secondaryBrand.50', 'neutral.800')
            : undefined
        }
        boxShadow={calculateBoxShadow()}
        _hover={
          readOnly
            ? {}
            : {
                bg: useColorModeValue('secondaryBrand.50', 'neutral.800'),
                boxShadow: calculateHoverBoxShadow(),
              }
        }
        role="group"
      >
        <Box display="flex" alignItems="center">
          {jsonSchema.isRequired && isMissing(initialValue) && (
            <Tooltip
              label="Required field is missing"
              hasArrow
              placement="left"
            >
              <Icon
                mr={1}
                boxSize={5}
                as={ExclamationCircleIcon}
                color="red.500"
              />
            </Tooltip>
          )}
          <FieldTitle
            title={jsonSchema.label}
            description={jsonSchema.description}
            hideLabel={hideLabel}
            isRequired={jsonSchema.isRequired}
          />
        </Box>

        {!displayComponent && (
          <FieldWrapper
            schema={jsonSchema}
            initialValue={initialValue}
            mode={mode}
            isSaving={isSaving}
            onCancel={onCancel}
            onEdit={onEditWrapper}
          />
        )}
        {displayComponent}
      </Stack>
    </Tooltip>
  );
};

const FieldWrapper = ({
  schema,
  mode,
  initialValue,
  isSaving,
  onCancel,
  onEdit,
}: any) => {
  const field = useField(initialValue);
  const fieldRef = useRef<any>();

  return (
    <>
      <CustomFieldRenderer
        ref={fieldRef}
        schema={schema}
        value={field.value}
        onChange={e => {
          field.setValue(e);
        }}
        mode={mode}
        onError={field.setErrors}
      />
      {Array.isArray(field.errors) && field.errors.length > 0 && (
        <Text fontSize="small" color="red.500">
          {field.errors[0]}
        </Text>
      )}
      {mode === 'edit' && (
        <HStack alignSelf={'flex-end'} data-testid="editable-field-actions">
          <Button
            variant={'ghost'}
            isDisabled={isSaving}
            onClick={() => {
              field.setValue(initialValue);
              onCancel();
            }}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            isLoading={isSaving}
            isDisabled={field.errors.length > 0 || isSaving}
            onClick={() => {
              const errors = fieldRef.current!.validate(field.value);
              if (errors.length === 0) {
                onEdit(field.value);
              } else {
                field.setErrors(errors);
              }
            }}
            variant={'primary'}
          >
            Save
          </Button>
        </HStack>
      )}
    </>
  );
};

export default ManagedField;
