import Form from '@rjsf/chakra-ui';
import validator from '@rjsf/validator-ajv8';
import { UiSchema } from '@rjsf/utils';
import { FieldEditProps } from '../../index';
import { HStack, Button } from '@chakra-ui/react';

const RegularFieldEdit = ({
  field,
  onEdit,
  onCancel,
  onChange,
}: FieldEditProps) => {
  const formData = {
    [field.key]: field.value,
  };

  const customUiSchema = field.settings.properties?.[field.key].uiSchema;

  const uiSchema: UiSchema = {
    'ui:globalOptions': { label: false },
    [field.key]: customUiSchema ?? {},
  };

  const handleSubmit = (e: any) => {
    onEdit(e.formData);
  };

  const handleCancel = () => {
    onCancel({});
  };

  return (
    <Form
      schema={field.schema}
      formData={formData}
      validator={validator}
      onSubmit={handleSubmit}
      uiSchema={uiSchema}
      className="custom-field-edit-form"
      onChange={e => {
        onChange?.(e.formData);
      }}
      liveValidate={!!onChange}
      showErrorList={false}
    >
      {!onChange ? (
        <HStack justifyContent={'flex-end'}>
          <Button
            onClick={() => {
              handleCancel();
            }}
            variant={'ghost'}
          >
            Cancel
          </Button>
          <Button type="submit" variant={'primary'}>
            Save
          </Button>
        </HStack>
      ) : (
        <></>
      )}
    </Form>
  );
};

export default RegularFieldEdit;
