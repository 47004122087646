import {
  Stack,
  Fade,
  Tooltip,
  useColorModeValue,
  Icon,
  Skeleton,
  HStack,
} from '@chakra-ui/react';
import { PencilIcon } from '@heroicons/react/24/outline';
import CustomFieldDisplay from '../CustomFieldDisplay';
import CustomFieldEdit from '../CustomFieldEdit';
import { getSchemaPropertyByKey } from '../../../../utils';
import { useState, useRef, useEffect } from 'react';
import { TInventoryModelCustomField } from '../../../../models/inventory_model';
import MoreInfoPopOver from '../../../MoreInfoPopOver';
import { Label } from '../../../Layout';
import _ from 'lodash';

interface CustomFieldFormProps {
  customField: TInventoryModelCustomField;
  onEdit: (data: any) => void;
  onCancel: (data?: any) => void;
  readOnly?: boolean;
  hideLabel?: boolean;
  isLoading?: boolean;
  displayComponent?: (field: TInventoryModelCustomField) => JSX.Element;
}

interface CustomFieldTitleProps {
  property: any;
  settings: any;
  hideLabel?: boolean;
}

const CustomFieldTitle = ({
  settings,
  property,
  hideLabel,
}: CustomFieldTitleProps) => {
  if (hideLabel) {
    return null;
  }
  return (
    <HStack w={'full'} alignItems={'flex-start'}>
      <Label>{property.title!}</Label>
      {settings.description && (
        <MoreInfoPopOver
          title={property.title!}
          description={settings.description!}
          iconProps={{
            mt: '-2px',
            opacity: 0,
            _groupHover: { opacity: 1 },
          }}
        />
      )}
    </HStack>
  );
};

const CustomFieldForm = ({
  customField,
  onEdit,
  onCancel,
  readOnly = false,
  hideLabel = false,
  isLoading = false,
  displayComponent,
}: CustomFieldFormProps) => {
  const [isEditing, setIsEditing] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const stackRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isEditing) {
      setIsActive(true);
    }
  }, [isEditing]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (stackRef.current && !stackRef.current.contains(event.target)) {
        setIsActive(false);
      }
    }

    function handleKeyPress(event: any) {
      if (event.key === 'Escape') {
        onCancel();
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('touchstart', handleClickOutside);
    document.addEventListener('keyup', handleKeyPress);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('touchstart', handleClickOutside);
      document.removeEventListener('keyup', handleKeyPress);
    };
  }, [stackRef]);

  const calculateBoxShadow = () => {
    if (isActive && isEditing)
      return useColorModeValue(
        `0px 0px 0px 7px var(--chakra-colors-secondaryBrand-50),
        0px 0px 0px 8px var(--chakra-colors-secondaryBrand-200),
        0px 0px 0px 9px var(--chakra-colors-brand-base),
        0px 0px 0px 10px var(--chakra-colors-secondaryBrand-50)`,
        `0px 0px 0px 7px var(--chakra-colors-neutral-800),
        0px 0px 0px 8px var(--chakra-colors-neutral-850),
        0px 0px 0px 9px var(--chakra-colors-brand-base),
        0px 0px 0px 10px var(--chakra-colors-brand-900)`,
      );
    if (!isActive && isEditing)
      return useColorModeValue(
        '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50)',
        '0px 0px 0px 8px var(--chakra-colors-neutral-800)',
      );
    return '0px 0px 0px 8px transparent, 0px 0px 0px 9px transparent';
  };

  const calculateHoverBoxShadow = () => {
    if (isEditing && isActive)
      return useColorModeValue(
        '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50), 0px 0px 0px 9px var(--chakra-colors-brand-base)',
        '0px 0px 0px 8px var(--chakra-colors-neutral-800), 0px 0px 0px 9px var(--chakra-colors-brand-base)',
      );
    if (isEditing && !isActive)
      return useColorModeValue(
        '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50), 0px 0px 0px 9px var(--chakra-colors-secondaryBrand-200)',
        '0px 0px 0px 8px var(--chakra-colors-neutral-800), 0px 0px 0px 9px var(--chakra-colors-neutral-700)',
      );
    return useColorModeValue(
      '0px 0px 0px 8px var(--chakra-colors-secondaryBrand-50), 0px 0px 0px 9px var(--chakra-colors-secondaryBrand-100)',
      '0px 0px 0px 8px var(--chakra-colors-neutral-800), 0px 0px 0px 9px var(--chakra-colors-neutral-800)',
    );
  };

  const [property, settings] = getSchemaPropertyByKey(
    customField,
    customField.key,
  );

  const onCancelClick = () => {
    setIsEditing(false);
    onCancel();
  };

  const onEditClick = (data: any) => {
    setIsEditing(false);
    onEdit(data);
  };

  if (isLoading) {
    return (
      <Stack w={'full'}>
        <Skeleton height={4} w={`${_.random(20, 90, false)}%`} />;
        <Skeleton height={10} w={'full'} />;
      </Stack>
    );
  }

  return (
    <Tooltip
      gutter={0}
      fontSize="md"
      openDelay={50}
      offset={[9, 4]}
      boxShadow={'none'}
      placement="top-end"
      isDisabled={isEditing || readOnly}
      label={<Icon as={PencilIcon} boxSize={4} />}
      px={2}
      py={1}
      roundedTop={'md'}
      roundedBottom={'none'}
      bg={useColorModeValue('secondaryBrand.50', 'neutral.800')}
      color={useColorModeValue('neutral.800', 'neutral.200')}
      borderTop={'1px solid '}
      borderRight={'1px solid '}
      borderLeft={'1px solid '}
      borderColor={useColorModeValue('secondaryBrand.100', 'neutral.850')}
    >
      <Stack
        ref={stackRef}
        w={'full'}
        gap={2}
        borderRadius={'2px'}
        cursor={isEditing || readOnly ? 'default' : 'pointer'}
        onClick={() => {
          setIsActive(true);
          if (!isEditing && !readOnly) {
            setIsEditing(true);
          }
        }}
        bg={
          isEditing
            ? useColorModeValue('secondaryBrand.50', 'neutral.800')
            : useColorModeValue('white', 'neutral.950')
        }
        boxShadow={calculateBoxShadow()}
        _hover={
          readOnly
            ? {}
            : {
                bg: useColorModeValue('secondaryBrand.50', 'neutral.800'),
                boxShadow: calculateHoverBoxShadow(),
              }
        }
        role="group"
        data-testid="custom-field"
      >
        <CustomFieldTitle
          settings={settings}
          property={property}
          hideLabel={hideLabel}
        />
        {isEditing && !readOnly ? (
          <CustomFieldEdit
            customField={customField}
            onEdit={onEditClick}
            onCancel={onCancelClick}
          />
        ) : (
          <Fade
            in={true}
            transition={{ enter: { delay: 0.1, duration: 0.25 } }}
          >
            {displayComponent ? (
              <>{displayComponent(customField)}</>
            ) : (
              <CustomFieldDisplay customField={customField} />
            )}
          </Fade>
        )}
      </Stack>
    </Tooltip>
  );
};

export default CustomFieldForm;
