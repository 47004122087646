import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  HStack,
  useToast,
} from '@chakra-ui/react';
import ReactFlow, {
  Background,
  Connection,
  Controls,
  Edge,
  MarkerType,
  MiniMap,
  Node,
  Panel,
  ReactFlowInstance,
  ReactFlowProvider,
  addEdge,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from 'reactflow';
import { useCallback, useEffect, useState, useContext } from 'react';
import ApprovalNode from '../nodes/ApprovalNode';
import StatusFormNode from '../nodes/StatusFormNode';
import StatusSetNode from '../nodes/StatusSetNode';
import StartNode from '../nodes/StartNode';
import { upsertNode, cleanEdges } from '../utils';
import _ from 'lodash';
import NodeSettings from '../Panels/NodeSettings';
import NodesBar from '../Panels/NodesBar';
import ApprovalPanel from '../Panels/ApprovalPanel';
import StatusSetPanel from '../Panels/StatusSetPanel';
import StatusFormPanel from '../Panels/StatusFormPanel';
import { ContentPageTitle } from '../../../../../components/Layout';
import FloatingEdge from '../edges/FloatingEdge';
import FloatingConnectionLine from '../edges/FloatingEdge/FloatingConnectionLine';
import useWorkflow from '../../../../../hooks/useWorkflow';
import UsersContext from '../../../../../contexts/UsersContext';

const initialNodes: Node[] = [];
const initialEdges: Edge[] = [];

export const nodeTypes = {
  [ApprovalNode.type]: ApprovalNode,
  [StatusFormNode.type]: StatusFormNode,
  [StatusSetNode.type]: StatusSetNode,
  [StartNode.type]: StartNode,
};

export const edgeTypes = {
  floating: FloatingEdge,
};

export const panels = {
  [StatusSetNode.type]: StatusSetPanel,
  [StatusFormNode.type]: StatusFormPanel,
  [ApprovalNode.type]: ApprovalPanel,
};

export default function WorkflowCanvas() {
  return (
    <ReactFlowProvider>
      <Canvas />
    </ReactFlowProvider>
  );
}

const Canvas = () => {
  const toast = useToast();
  const { deleteElements } = useReactFlow();
  const { workflow, saveSource, setSelectedNodeId } = useWorkflow();
  const [reactFlowInstance, setReactFlowInstance] =
    useState<ReactFlowInstance>();
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const { userHasPermission } = useContext(UsersContext);

  useEffect(() => {
    setNodes(workflow?.version.source.nodes || []);
    setEdges(cleanEdges(workflow?.version.source.edges || []));
  }, [workflow?.version.source]);

  // listen when the user connects two nodes to modify the edge and the source and target node data
  const onConnect = useCallback(
    (params: Connection) => {
      const sourceNode = nodes.find(node => node.id === params.source)!;
      const targetNode = nodes.find(node => node.id === params.target)!;

      // validate connections first:
      // ApprovalNode can link only with StatusSetNode nodes
      if (
        sourceNode.type === ApprovalNode.type &&
        targetNode.type !== StatusSetNode.type
      ) {
        toast({
          title: 'Workflow Validation - Approvals',
          description:
            'Approval action results can only connect to status changes',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }
      // ApprovalNode sources can connect max with two targets
      if (
        sourceNode.type === ApprovalNode.type &&
        edges.filter(edge => edge.source === params.source).length >= 2
      ) {
        toast({
          title: 'Workflow Validation - Approvals',
          description:
            'Approval action results can only connect to two outcomes',
          status: 'warning',
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      // continue defining the edge parameters based on source and target nodes

      // define the edge parameters based on source and target nodes
      const edgeParams: any = {
        ...params,
        // animated: nodeTypes[targetNode!.type!].autoRuns,
        // type: 'floating',
        label:
          sourceNode.type === ApprovalNode.type
            ? `when ${params.sourceHandle}`
            : undefined,
      };

      // set the on_approved or on_rejected callback to the target node
      let clonedNode = _.cloneDeep(sourceNode);
      if (params.sourceHandle === 'approved') {
        clonedNode.data.state_callbacks.on_enter[0].args.on_approved =
          params.target;
      } else if (params.sourceHandle === 'rejected') {
        clonedNode.data.state_callbacks.on_enter[0].args.on_rejected =
          params.target;
      }

      setNodes(currentNodes => upsertNode(currentNodes, clonedNode));
      setEdges(eds => addEdge(edgeParams, eds));
    },
    [setEdges, edges, nodes],
  );

  const onEdgesDelete = useCallback(
    (edges: Edge[]) => {
      edges.forEach(edge => {
        const sourceNode = nodes.find(node => node.id === edge.source)!;
        const targetNode = nodes.find(node => node.id === edge.target)!;

        // for approval nodes edges delete, remove the on_approved or on_rejected callback from the source node
        if (edge.sourceHandle === 'approved') {
          const clonedNode = _.cloneDeep(sourceNode);
          clonedNode.data.state_callbacks.on_enter[0].args.on_approved = null;
          setNodes(currentNodes => upsertNode(currentNodes, clonedNode));
        } else if (edge.sourceHandle === 'rejected') {
          const clonedNode = _.cloneDeep(sourceNode);
          clonedNode.data.state_callbacks.on_enter[0].args.on_rejected = null;
          setNodes(currentNodes => upsertNode(currentNodes, clonedNode));
        }
      });
    },
    [edges, setEdges],
  );

  const onDragOver = useCallback((event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = 'move';
  }, []);

  const onDrop = useCallback(
    (event: any) => {
      event.preventDefault();

      const type = event.dataTransfer.getData('application/reactflow');

      // check if the dropped element is valid
      if (typeof type === 'undefined' || !type) {
        return;
      }

      const position = reactFlowInstance!.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const node = nodeTypes[type].getDefaultNode();
      const newNode = {
        ...node,
        position,
      };

      setNodes(nds => nds.concat(newNode));
    },
    [reactFlowInstance],
  );

  const onAddNode = (node: Node) => {
    setNodes(currentNodes => upsertNode(currentNodes, node));
  };

  const onDeleteNode = useCallback(
    (node: Node) => {
      setSelectedNodeId!();
      deleteElements({ nodes: [node] });
    },
    [setNodes, setEdges],
  );

  const canUpdateWorkflow = userHasPermission(['update_workflow'], 'all');

  return (
    <>
      <ReactFlow
        onInit={instance => setReactFlowInstance(instance)}
        nodes={nodes}
        edges={edges}
        onConnect={onConnect}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onEdgesDelete={onEdgesDelete}
        onDrop={onDrop}
        onDragOver={onDragOver}
        nodeTypes={nodeTypes}
        edgeTypes={edgeTypes}
        connectionLineComponent={FloatingConnectionLine}
        fitView={true}
        fitViewOptions={{ padding: 1 }}
        defaultEdgeOptions={{
          type: 'floating',
          markerEnd: { type: MarkerType.ArrowClosed, width: 12, height: 12 },
          markerStart: {
            type: MarkerType.ArrowClosed,
            orient: 'auto',
            width: 12,
            height: 12,
          },
          style: { strokeWidth: 2, stroke: 'var(--chakra-colors-neutral-400)' },
        }}
      >
        <Background />
        <MiniMap position="bottom-left" />
        <Controls position="bottom-right" />

        <Panel position={'top-left'}>
          <HStack mt={9} alignItems={'flex-end'}>
            <Box>
              <Breadcrumb color="neutral.500">
                <BreadcrumbItem>
                  <BreadcrumbLink href="/settings">Settings</BreadcrumbLink>
                </BreadcrumbItem>

                <BreadcrumbItem>
                  <BreadcrumbLink href="/settings/workflows">
                    Workflows
                  </BreadcrumbLink>
                </BreadcrumbItem>
              </Breadcrumb>
              <ContentPageTitle>
                {workflow?.title} (v{workflow?.version.number})
              </ContentPageTitle>
            </Box>
            {canUpdateWorkflow && (
              <Button
                isLoading={saveSource?.isLoading}
                onClick={() => saveSource?.mutate({ nodes, edges } as any)}
              >
                Save Workflow
              </Button>
            )}
          </HStack>
        </Panel>
        {canUpdateWorkflow && (
          <Panel position={'bottom-center'}>
            <NodesBar />
          </Panel>
        )}
        <Panel position={'top-left'}>
          <Box mt={28}>
            <NodeSettings onAddNode={onAddNode} onDeleteNode={onDeleteNode} />
          </Box>
        </Panel>
      </ReactFlow>
    </>
  );
};
