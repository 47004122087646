import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import {
  EntityAttachment,
  EntityAttachmentFile,
  EntityType,
} from '../../models/entity_attachments';
import EditableField from '../EditableField';
import API from '../../api/API';
import { useAuth0 } from '@auth0/auth0-react';
import {
  VStack,
  Text,
  HStack,
  Box,
  IconButton,
  useToast,
  Icon,
  Button,
  Spacer,
  TagLabel,
  Tag,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  useDisclosure,
  Heading,
  useColorModeValue,
} from '@chakra-ui/react';
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline';
import {
  DefaultExtensionType,
  FileIcon,
  IconType,
  defaultStyles,
} from 'react-file-icon';
import { MimeTypeMap } from '../../mimeTypeMap';
import FileIconColors from './file-icon-colors';
import prettyBytes from 'pretty-bytes';
import { UsersContext } from '../../contexts';
import AvatarProxy from '../AvatarProxy';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { displayFormatedDateAndTime } from '../../utils';

type RemoveAttachmentDetails = {
  isLocal: boolean;
  index: number;
};

type RemoveAttachmentModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  isLocal?: boolean;
};

const RemoveAttachmentModal = ({
  isOpen,
  onClose,
  onConfirm,
  isLocal,
}: RemoveAttachmentModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Remove Attachment</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <VStack align="flex-start">
            <Text>Are you sure you want to remove this attachment?</Text>
            {!isLocal && (
              <Text>
                The file will be removed when you hit Save on the Attachments.
              </Text>
            )}
          </VStack>
        </ModalBody>
        <ModalFooter>
          <Button mr={3} onClick={onClose}>
            No, Cancel
          </Button>
          <Button variant="ghost" onClick={onConfirm}>
            Yes, Delete it
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

type FileDisplayProps = {
  isLocal: boolean;
  fileName: string;
  size: number;
  mimeType: string;
  canDelete: boolean;
  userCUID?: string;
  createdAt: number;
  onDelete: (e: React.MouseEvent) => void;
  onClick?: (e: React.MouseEvent) => void;
};

const FileDisplay = (props: FileDisplayProps) => {
  const fileExtension = props.fileName.split('.').pop() || 'file';

  // If custom style def do not have labelColor, use #777
  const customDefaultLabelColor = FileIconColors[fileExtension]
    ? FileIconColors[fileExtension]['labelColor'] ?? '#777'
    : '#777';

  // Library defined default labelCOlor
  const libDefaultGlyphColor =
    defaultStyles[fileExtension as DefaultExtensionType] &&
    defaultStyles[fileExtension as DefaultExtensionType]['labelColor'];

  const { organizationUsers, currentUser } = useContext(UsersContext);

  const uploadedByUser =
    organizationUsers.find(user => user.cuid === props.userCUID) || currentUser;

  return (
    <Box w="full">
      <HStack
        borderWidth={1}
        borderColor={useColorModeValue('neutral.300', 'neutral.700')}
        bgColor={useColorModeValue('white', 'neutral.900')}
        borderRadius={'md'}
        justifyContent="center"
        cursor={'pointer'}
        _hover={{
          bgColor: useColorModeValue('secondaryBrand.50', 'neutral.700'),
        }}
        style={{
          justifyContent: 'flex-start',
        }}
        py={2}
        px={4}
        // pr={props.canDelete ? 10 : 2}
        onClick={props.onClick}
      >
        <Box h="full" w={8} mr={2} flexShrink={0}>
          <FileIcon
            type={(MimeTypeMap as any)[props.mimeType] as IconType}
            extension={fileExtension}
            glyphColor={libDefaultGlyphColor ?? customDefaultLabelColor}
            labelColor={customDefaultLabelColor}
            {...defaultStyles[fileExtension as DefaultExtensionType]}
            {...FileIconColors[fileExtension]}
          />
        </Box>
        <HStack gap={4} w="full">
          <VStack align="flex-start" gap={0}>
            <Text fontSize="sm" overflowWrap="anywhere" fontWeight={'bold'}>
              {props.fileName}
            </Text>
            <Text fontSize="sm">
              Uploaded on {displayFormatedDateAndTime(props.createdAt)}
            </Text>
          </VStack>
          <Spacer />

          <Tag size={'md'} borderRadius="full">
            <AvatarProxy
              src={uploadedByUser?.picture}
              size="xs"
              name={uploadedByUser?.name}
              ml={-2}
              mr={2}
            />
            <TagLabel>{uploadedByUser?.name}</TagLabel>
          </Tag>
          <Text fontSize="small" fontFamily={'mono'}>
            {prettyBytes(props.size)}
          </Text>
          {props.canDelete && (
            <IconButton
              variant="danger"
              aria-label="Delete"
              icon={<Icon as={TrashIcon} boxSize={6} />}
              color={'neutral.400'}
              size="sm"
              _hover={{
                bg: 'red.100',
                color: 'red.600',
              }}
              onClick={props.onDelete}
            />
          )}
        </HStack>
      </HStack>
    </Box>
  );
};

type UploadFileButtonProps = {
  onClick: (e: React.MouseEvent) => void;
};

const UploadFileButton = ({ onClick }: UploadFileButtonProps) => {
  return (
    <Button
      onClick={onClick}
      display="flex"
      justifyContent="center"
      p={6}
      w="full"
      leftIcon={<Icon as={PlusIcon} boxSize={5} />}
      variant={'outline'}
    >
      <Text>Select Files</Text>
    </Button>
  );
};

type Props = {
  entityType: EntityType;
  entityCuid: string;
  entityFieldId: string;
  canUpload: boolean;
  canDelete: boolean;
  maxFileSizeBytes?: number;
  title?: string;
};

export default function ManageAttachmentsField({
  entityType,
  entityCuid,
  entityFieldId,
  canUpload,
  canDelete,
  maxFileSizeBytes,
  title = 'Attachments',
}: Props) {
  const fileUploadRef = useRef<HTMLInputElement>(null);
  const [entityAttachments, setEntityAttachments] =
    useState<EntityAttachment>();
  const { getAccessTokenSilently } = useAuth0();
  const { globalMaxUploadSize } = useFlags();

  const [attachmentsToDelete, setAttachmentsToDelete] = useState<
    EntityAttachmentFile[]
  >([]);
  const [filesToUpload, setFilesToUpload] = useState<File[]>([]);
  const toast = useToast();
  const removeModal = useDisclosure();
  const [removeModalAttachment, setRemoveModalAttachment] =
    useState<RemoveAttachmentDetails>();

  // If maxFileSizeBytes is not provided, use the globalMaxUploadSize flag
  // from LaunchDarkly. If that is not set, use the default value of 20MB
  if (!maxFileSizeBytes) {
    if (globalMaxUploadSize) {
      maxFileSizeBytes = globalMaxUploadSize as number;
    } else {
      // LaunchDarkly flag not set, use default
      // 20MB
      maxFileSizeBytes = 20000000;
    }
  }

  const flagForRemoteDeletion = useCallback(
    (attachment: EntityAttachmentFile) => {
      // Flag the attachment for deletion
      setAttachmentsToDelete([...attachmentsToDelete, attachment]);
    },
    [attachmentsToDelete],
  );

  const localDelete = useCallback(
    (fileIndex: number) => {
      // Remove the file from the list of files to upload
      const newFilesToUpload = [...filesToUpload];
      newFilesToUpload.splice(fileIndex, 1);
      setFilesToUpload(newFilesToUpload);

      // Remove file from the input field
      if (fileUploadRef.current && fileUploadRef.current.files) {
        fileUploadRef.current.files;
        const dataTransfer = new DataTransfer();
        const files = fileUploadRef.current.files;
        for (let i = 0; i < files.length; i++) {
          if (i !== fileIndex) {
            dataTransfer.items.add(files[i]);
          }
        }
        fileUploadRef.current.files = dataTransfer.files;
      }
    },
    [filesToUpload],
  );

  const handleFileUpload = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        const targetFilesArray = Array.from(e.target.files);

        // Check if the files are within the max file size
        const validFiles: File[] = [];
        // Files that exceed the max file size
        const invalidFiles: File[] = [];

        targetFilesArray.forEach(file => {
          if (file.size <= maxFileSizeBytes!) {
            validFiles.push(file);
          } else {
            invalidFiles.push(file);
          }
        });

        if (invalidFiles.length > 0) {
          toast({
            title: 'Some of those files are too big.',
            description: `One or more files you are trying to attach exceed the maximum file size of ${prettyBytes(
              maxFileSizeBytes!,
            )}. \n\nThe following files will not be added:\n ${invalidFiles
              .map(file => `• ${file.name} (${prettyBytes(file.size)})`)
              .join('.\n ')}`,
            status: 'warning',
            duration: null,
            isClosable: true,
            containerStyle: {
              whiteSpace: 'pre-line',
            },
            variant: 'subtle',
          });
        } else {
          toast({
            title: 'Your files are selected!',
            description: `Make sure you click 'Save' on the attachments field to upload your selected files.`,
            status: 'success',
            duration: 4000,
            isClosable: true,
            containerStyle: {
              whiteSpace: 'pre-line',
            },
            variant: 'subtle',
          });
        }
        if (validFiles.length > 0) {
          setFilesToUpload([...filesToUpload, ...validFiles]);
        }
      }
    },
    [filesToUpload],
  );

  const clearPendingAttachments = useCallback(() => {
    setAttachmentsToDelete([]);
    setFilesToUpload([]);
  }, []);

  useEffect(() => {
    (async () => {
      const accessToken = await getAccessTokenSilently();
      try {
        const attachments = await API.GetEntityAttachments(
          accessToken,
          entityType,
          entityCuid,
          entityFieldId,
        );
        setEntityAttachments(attachments);
      } catch (e: any) {
        if (e.response?.status !== 404) {
          console.log(e);
        }
      }
    })();
  }, [entityType, entityCuid, entityFieldId]);

  const nonDeletedAttachments =
    entityAttachments?.attachments.filter(
      attachment =>
        // filter out attachmentsToDelete
        !attachmentsToDelete.some(
          attachmentToDelete => attachmentToDelete.id === attachment.id,
        ),
    ) || [];

  return (
    <>
      <RemoveAttachmentModal
        isOpen={removeModal.isOpen}
        onClose={removeModal.onClose}
        isLocal={removeModalAttachment?.isLocal}
        onConfirm={() => {
          removeModal.onClose();
          if (removeModalAttachment) {
            if (removeModalAttachment.isLocal) {
              localDelete(removeModalAttachment.index);
            } else {
              if (nonDeletedAttachments) {
                flagForRemoteDeletion(
                  nonDeletedAttachments[removeModalAttachment.index],
                );
              }
            }
          }
        }}
      />
      <EditableField
        title={title}
        moreInfoPopoverProps={{
          title: title,
          description: `Upload supporting documents. Files must be less than ${prettyBytes(
            maxFileSizeBytes!,
          )} in size. After upload, files can be viewed or downloaded.`,
          link: 'https://docs.validmind.ai/guide/model-validation/add-manage-model-findings.html#manage-supporting-documentation',
          placement: 'right-end',
          iconProps: {
            ml: 2,
            opacity: 0,
            _groupHover: { opacity: 1 },
          },
        }}
        value={entityAttachments?.attachments || []}
        onCancel={() => {
          clearPendingAttachments();
        }}
        onSave={onFinished => {
          (async () => {
            const accessToken = await getAccessTokenSilently();
            try {
              if (attachmentsToDelete.length > 0) {
                const newEntityAttachments =
                  await API.DeleteEntityAttachmentsFiles(
                    accessToken,
                    entityType,
                    entityCuid,
                    entityFieldId,
                    attachmentsToDelete.map(attachment => attachment.id),
                  );
                setEntityAttachments(newEntityAttachments);
              }
              if (filesToUpload.length > 0) {
                const newEntityAttachments =
                  await API.UploadEntityAttachmentsFiles(
                    accessToken,
                    entityType,
                    entityCuid,
                    entityFieldId,
                    filesToUpload,
                  );
                setEntityAttachments(newEntityAttachments);
              }
              onFinished(true);
            } catch (e: any) {
              console.log(e);
              toast({
                title: 'Error',
                description: 'Failed to save attachments',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
              onFinished(false);
            } finally {
              clearPendingAttachments();
            }
          })();
        }}
        readOnly={!canUpload && !canDelete}
        renderField={(mode, isSaving) => {
          if (
            (entityAttachments && entityAttachments?.attachments.length > 0) ||
            mode === 'edit'
          ) {
            return (
              <>
                <input
                  ref={fileUploadRef}
                  type="file"
                  multiple
                  hidden
                  onChange={handleFileUpload}
                />
                <VStack alignItems="flex-start" w="full" gap={1}>
                  {nonDeletedAttachments.map((attachment, attachmentIndex) => (
                    <FileDisplay
                      key={attachment.id}
                      isLocal={false}
                      fileName={attachment.name}
                      size={attachment.size_in_bytes}
                      mimeType={attachment.content_type}
                      canDelete={!isSaving && mode === 'edit' && canDelete}
                      userCUID={attachment.uploaded_by_cuid}
                      onDelete={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setRemoveModalAttachment({
                          isLocal: false,
                          index: attachmentIndex,
                        });
                        removeModal.onOpen();
                      }}
                      createdAt={attachment.created_at}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        window.open(attachment.url, '_blank');
                      }}
                    />
                  ))}
                  {filesToUpload.map((file, index) => (
                    <FileDisplay
                      key={index}
                      isLocal={true}
                      fileName={file.name}
                      size={file.size}
                      mimeType={file.type}
                      canDelete={!isSaving && mode === 'edit' && canDelete}
                      createdAt={Date.now() / 1000}
                      onDelete={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        setRemoveModalAttachment({
                          isLocal: true,
                          index,
                        });
                        removeModal.onOpen();
                      }}
                    />
                  ))}
                  {!isSaving && mode === 'edit' && canUpload && (
                    <UploadFileButton
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        if (!fileUploadRef || !fileUploadRef.current) return;

                        fileUploadRef.current.click();
                      }}
                    />
                  )}
                </VStack>
              </>
            );
          }
          return (
            <Box
              py={4}
              px={4}
              w={'full'}
              rounded={'md'}
              border={'1px dashed'}
              bg={useColorModeValue('white', 'neutral.900')}
              borderColor={useColorModeValue('neutral.300', 'neutral.700')}
              textAlign={'center'}
            >
              <VStack
                spacing={1}
                fontStyle={'oblique'}
                color={useColorModeValue('neutral.500', 'neutral.400')}
                gap={2}
              >
                <Heading as={'h5'}>No attachments yet.</Heading>
                <Text>Click to add attachments.</Text>
              </VStack>
            </Box>
          );
        }}
      />
    </>
  );
}
