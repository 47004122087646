import { SVGProps } from 'react';

function ValidMindLogo(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="150"
      height="26"
      viewBox="0 0 150 26"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      aria-labelledby="validMind validMindDescription"
      role="img"
    >
      <title id="validMindLogo">
        ValidMind - Model Risk Management, Simplified.
      </title>
      <desc id="validMindDescription">
        ValidMind increases the speed and efficiency of model validation
        activities for Financial Institutions.
      </desc>
      {/* <path
        d="M149.552 6.84549H149.158H149.05L148.563 8.20434L148.077 6.84549H147.97H147.576H147.376V8.98263H147.97V7.87507L148.394 8.98263H148.731L149.158 7.87113V8.98263H149.753V6.84549H149.552Z"
        fill="#083E44"
      />
      <path
        d="M145.109 7.32759H145.758V8.98263H146.354V7.32759H147.005V6.84549H145.109V7.32759Z"
        fill="#083E44"
      /> */}
      <path
        d="M73.7453 21.2257C72.9529 22.6602 71.8122 23.7772 70.325 24.5792C68.8377 25.3811 67.0873 25.7812 65.0747 25.7812H57.9401V6.8896H65.0747C67.1055 6.8896 68.8598 7.2819 70.3391 8.0657C71.8177 8.8495 72.9529 9.95392 73.7461 11.3789C74.5386 12.8048 74.9356 14.4433 74.9356 16.296C74.9356 18.1488 74.5386 19.792 73.7461 21.2257H73.7453ZM68.829 20.2773C69.8176 19.3336 70.3124 18.0062 70.3124 16.296C70.3124 14.5858 69.8176 13.2593 68.829 12.3148C67.8404 11.3711 66.4548 10.8984 64.6737 10.8984H62.509V21.6937H64.6737C66.4548 21.6937 67.8404 21.2218 68.829 20.2773Z"
        fill="#DE257E"
      />
      <path
        d="M125.545 25.7663H120.975L113.333 14.1691V25.7663H108.764V6.87384H113.333L120.975 18.5773V6.87384H125.545V25.7655V25.7663Z"
        fill="#083E44"
      />
      <path d="M106.09 6.87384V25.7655H101.52V6.87384H106.09Z" fill="#083E44" />
      <path
        d="M98.8468 6.94317V25.7663H94.2779V13.995L90.2163 25.7663H86.2618L82.1734 13.9683V25.7663H77.6045V6.94317H83.1888L88.2926 20.0551L93.2892 6.94317H98.8468Z"
        fill="#083E44"
      />
      <path
        d="M144.025 21.21C143.232 22.6445 142.092 23.7615 140.604 24.5634C139.117 25.3653 137.367 25.7655 135.354 25.7655H128.219V6.87384H135.354C137.385 6.87384 139.139 7.26614 140.618 8.04994C142.097 8.83375 143.232 9.93816 144.025 11.3632C144.818 12.789 145.215 14.4275 145.215 16.2803C145.215 18.1331 144.818 19.7763 144.025 21.21H144.025ZM139.108 20.2615C140.096 19.3178 140.591 17.9905 140.591 16.2803C140.591 14.5701 140.096 13.2435 139.108 12.299C138.119 11.3553 136.733 10.8827 134.952 10.8827H132.787V21.6779H134.952C136.733 21.6779 138.119 21.206 139.108 20.2615Z"
        fill="#083E44"
      />
      <path
        d="M55.2586 6.87384V25.7655H50.6889V6.87384H55.2586Z"
        fill="#DE257E"
      />
      <path
        d="M41.9095 22.2656H48.0019V25.7663H37.3398V6.87384H41.9095V22.2656Z"
        fill="#DE257E"
      />
      <path
        d="M30.3777 22.2632H23.2967L22.1206 25.7253H17.3107L25.0983 6.88724H28.629L36.4166 25.7253H31.5531L30.377 22.2632H30.3777ZM29.2016 18.7743L26.8502 12.6677L24.472 18.7743H29.2016Z"
        fill="#DE257E"
      />
      <path
        d="M2.68069 12.0596H7.62535L6.73993 10.3565H1.79211L2.68069 12.0596Z"
        fill="#DE257E"
      />
      <path
        d="M8.38714 13.5263H3.44641L4.44291 15.435H9.3789L8.38714 13.5263Z"
        fill="#DE257E"
      />
      <path
        d="M18.8436 0L11.1103 18.7664L10.1414 16.9018H5.20776L9.71917 25.745H12.5598L23.4976 0H18.8436Z"
        fill="#DE257E"
      />
      <path
        d="M5.97818 8.89047L4.95569 6.9219H0L1.02722 8.89047H5.97818Z"
        fill="#DE257E"
      />
    </svg>
  );
}

export default ValidMindLogo;
